import type { Integration, Participant } from 'typings'
import {
  getColumnWidth,
  getNameColumnWidth,
  getPlacementColumnWidth,
  getSumColumnWidth,
} from './size'
import type { ColDef } from 'ag-grid-community'
import { PlayerNameCellRenderer } from './PlayerNameCellRenderer'
import type { Size } from './index'
import type { Term } from 'common/terms'
import { ViewMode } from './index'
import { getTrendIndicator } from './trend'
import terms from 'common/language'

export const getColumns = (
  fm: (_: Term) => string,
  participants: Participant[],
  size: Size,
  viewMode: ViewMode,
  integration?: Integration
) => {
  const columns: ColDef[] = [
    {
      field: 'placement',
      cellRenderer: (params) =>
        '<div class="placementCell"><div>' +
        (params.data.placement ? params.data.placement : '') +
        '</div>' +
        getTrendIndicator(params.data.trend) +
        '</div></div>',
      headerName:
        getPlacementColumnWidth(size) >= 70 ? fm(terms.placementShort) : '#',
      pinned: 'left',
      width: getPlacementColumnWidth(size),
    },
  ]
  columns.push({
    headerName: fm(terms.player),
    pinned: 'left',
    cellRendererFramework: PlayerNameCellRenderer,
    field: 'player',
    cellStyle: {
      justifyContent: 'flex-start',
    },
    width: getNameColumnWidth(size),
  })

  if (integration?.vendor === 'kluby') {
    columns.push({
      headerName: 'id',
      field: 'id',
      hide: true,
      pinned: 'left',
      width: 0,
    })
  }
  const columnWidth = getColumnWidth(participants, size)
  let prefix = `${fm(terms.round)} `
  if (columnWidth < 100) {
    prefix = ''
  }
  participants[0].scores.forEach((_, index) =>
    columns.push({
      cellStyle: (params) => (params.value.win ? { fontWeight: 'bold' } : {}),
      field: index.toString(),
      hide: viewMode !== 'detailed',
      headerName: `${prefix}${(1 + index).toString()}`,
      valueFormatter: (params) => params.value.score,
      width: columnWidth,
    })
  )
  columns.push({
    cellStyle: { fontWeight: 'bold' },
    field: 'wins',
    headerName: fm(terms.wins),
    hide: viewMode === 'detailed',
    valueFormatter: (params) => params.data.wins,
    width: columnWidth,
  })
  columns.push({
    headerName: fm(terms.total),
    width: getSumColumnWidth(),
    field: 'total',
    pinned: 'right',
  })

  return columns
}
