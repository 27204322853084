import {
  Store,
  applyMiddleware,
  legacy_createStore as createStore,
} from 'redux'
import { deleteState, loadState, saveState } from './localstorage'
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension'
import createSagaMiddleware from 'redux-saga'
import { isProd } from 'common/util'
import logger from 'redux-logger'
import rootReducer from 'reducers'
import rootSaga from 'sagas'

let initialState = loadState()

if (
  initialState &&
  (!initialState.commitHash ||
    initialState.commitHash !== import.meta.env.VITE_GIT_SHA)
) {
  console.log(
    'Missing or not matching stored commit hash. We have a new version, resetting state.'
  )
  deleteState()
  initialState = undefined
} else if (!initialState) {
  console.log('No initial state')
} else {
  console.log('Keeping previous state, it is compatible with current version.')
}

let store: Store

export default () => {
  let middleware = null

  const sagaMiddleware = createSagaMiddleware()

  if (isProd) {
    // In production we are adding only sagas middleware
    middleware = applyMiddleware(sagaMiddleware)
  } else {
    // In development mode beside sagaMiddleware
    // logger and DevTools are added
    // @ts-expect-error something wonky going on
    middleware = applyMiddleware(sagaMiddleware, logger)

    middleware = composeWithDevToolsDevelopmentOnly(middleware)
  }

  // Create store
  // with initial state if it exists
  store = createStore(rootReducer, initialState, middleware)

  store.subscribe(() => {
    saveState(store?.getState())
  })

  // Run root saga
  sagaMiddleware.run(rootSaga)

  // Enable Webpack hot module replacement for reducers
  if (import.meta.hot) {
    import.meta.hot.accept('reducers', () => {
      import('reducers/index').then((reducer) =>
        store?.replaceReducer(reducer.default)
      )
    })
  }

  // Return store only
  // But as an object for consistency
  return {
    store,
  }
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
