import padelCourtBlue from 'assets/img/padel-court-blue.jpg'
import padelCourtGreen from 'assets/img/padel-court-green.jpg'
import padelCourtOrange from 'assets/img/padel-court-orange.jpg'
import styled from 'styled-components'

export const CourtName = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 32px;
`

export const FullPage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  color: black;
  padding-top: 20px;
  margin-bottom: 60px;
  overflow: auto;
`

export const Participant = styled.div`
  width: 400px;
  text-align: left;
  color: black;
`

export const CourtStyle = styled.div`
  height: 343px;
  width: 587px;
  display: flex;
  border: none;
  flexdirection: row;
  background-image: ${(props) =>
    `url(${
      props.color === 'blue'
        ? padelCourtBlue
        : props.color === 'orange'
        ? padelCourtOrange
        : padelCourtGreen
    })`};
`

export const CourtHolder = styled.div``

export const CourtSide = styled.div`
  width: 294px;
  display: flex;
  flex-direction: column;
  padding-top: 6px;
`

export const LeftScorePositioner = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`

export const RightScorePositioner = styled.div`
  display: flex;
  justify-content: flex-begin;
  padding-left: 20px;
`

export const BoardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`

export const LeftNameHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`

export const RightNameHolder = styled.div`
  display: flex;
  justify-content: flex-begin;
  padding-left: 20px;
`

const Name = styled.div`
  color: white;
  font-size: 14pt;
  max-height: 60px;
  min-width: 160px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid black;
  border-radius: 300px;
  padding: 5px;
`

const UpperName = styled(Name)`
  margin-top: 80px;
`

const LowerName = styled(Name)`
  bottom: 80px;
`

export const LeftUpperName = styled(UpperName)``

export const RightUpperName = styled(UpperName)``

export const LeftLowerName = styled(LowerName)``

export const RightLowerName = styled(LowerName)``
