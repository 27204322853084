import { PhaseInfo } from 'typings'
import RoundCommandRow from './RoundCommandRow'
import SettingsCommandRow from './SettingsCommandRow'
import SummaryCommandRow from './SummaryCommandRow'

interface Props {
  phase: PhaseInfo
  width: number
}

const CommandRowContent = ({ phase: { phase }, width }: Props) => {
  switch (phase) {
    case 'round':
      return <RoundCommandRow width={width} />
    case 'summary':
      return <SummaryCommandRow width={width} />
    case 'settings':
      return <SettingsCommandRow width={width} />
  }
  return null
}

export default CommandRowContent
