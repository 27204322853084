import Button from '@material-ui/core/Button'
import SwishLogo from 'assets/img/swish.svg'
import SwishNagger from './SwishNagger'
import { useState } from 'react'

const SwishButton = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div
        style={{
          border: '1px solid gray',
          borderRadius: '20px',
          position: 'absolute',
          transform: 'translate(-120px, -70%)',
          visibility: open ? 'visible' : 'hidden',
        }}
      >
        <SwishNagger />
      </div>
      <Button
        style={{ fontSize: '10px', position: 'relative' }}
        size="small"
        onClick={() => setOpen(!open)}
      >
        <img
          draggable="false"
          onClick={() => console.log('hej')}
          style={{ height: '30px' }}
          src={SwishLogo}
          alt="swish"
        />
      </Button>
    </>
  )
}

export default SwishButton
