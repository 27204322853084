import * as actionCreators from 'actions'
import { ActionCreator, AnyAction, Dispatch, bindActionCreators } from 'redux'
import { Integration } from 'typings'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { deleteState } from 'localstorage'
import { useEffect } from 'react'

const QueryParamExtractor = ({
  addParticipant,
  addCourt,
  newTournament,
  removeCourts,
  removeParticipants,
  setIntegration,
}: {
  addCourt: (name: string) => void
  addParticipant: (name: string, id: string) => void
  newTournament: () => void
  removeCourts: () => void
  removeParticipants: () => void
  setIntegration: (integration: Integration) => void
}) => {
  useEffect(() => {
    const search = window.location.search
    if (!search) {
      return
    }
    // Assume we should start a new tournament
    deleteState()
    newTournament()

    const params = new URLSearchParams(search)

    const integration = params.get('integration')
    if (integration) {
      setIntegration({
        resultURL: params.get('resultURL') ?? undefined,
        returnURL: params.get('returnURL') ?? undefined,
        vendor: 'kluby',
      })
    }

    const players = params.get('players')
    if (players) {
      const participants = JSON.parse(players)
      if (participants.length % 2 !== 0) {
        console.log(
          'Players array must be of even length and contain pairs of name and id'
        )
        return
      }
      removeParticipants()

      for (let i = 0; i < participants.length; i += 2) {
        const name = participants[i]
        const id = participants[i + 1]
        addParticipant(name, id)
      }
    }
    const courtsParam = params.get('courts')
    if (courtsParam) {
      removeCourts()

      const courts = JSON.parse(courtsParam)
      for (const court of courts) {
        addCourt(court)
      }
    }

    window.location.href = window.location.origin

    /*    window.history.replaceState(
      {},
      document.title,
      window.location.origin + window.location.pathname
    )
    */
  }, [
    addCourt,
    addParticipant,
    newTournament,
    removeCourts,
    removeParticipants,
    setIntegration,
  ])

  return null
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators<ActionCreator<AnyAction>, ActionCreator<AnyAction>>(
    actionCreators as unknown as ActionCreator<AnyAction>,
    dispatch
  )

QueryParamExtractor.propTypes = {
  addCourt: PropTypes.func.isRequired,
  addParticipant: PropTypes.func.isRequired,
  newTournament: PropTypes.func.isRequired,
  removeCourts: PropTypes.func.isRequired,
  removeParticipants: PropTypes.func.isRequired,
  setIntegration: PropTypes.func.isRequired,
}

export default connect(() => ({}), mapDispatchToProps)(QueryParamExtractor)
