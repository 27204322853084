import { Court, Participant, PhaseInfo, Round } from 'typings'
import { RootState } from 'store'
import { TeamFormat } from 'common/constants'

export const courts = (state: RootState): Court[] => state.courts
export const currentPhase = (state: RootState): PhaseInfo => state.currentPhase
export const finalFormat = (state: RootState): string => state.finalFormat
export const language = (state: RootState): string => state.language
export const matchFormat = (state: RootState): string => state.matchFormat
export const mexicanoCourtSelection = (state: RootState): string =>
  state.mexicanoCourtSelection
export const participants = (state: RootState): Participant[] =>
  state.participants
export const resultSorting = (state: RootState): string => state.resultSorting
export const rounds = (state: RootState): Round[] => state.rounds
export const teamFormat = (state: RootState): TeamFormat => state.teamFormat
export const tournamentType = (state: RootState): string => state.tournamentType
