import type { Court, Match, Participant, Round } from 'typings'
import {
  CourtHolder,
  CourtSide,
  CourtStyle,
  LeftLowerName,
  LeftNameHolder,
  LeftScorePositioner,
  LeftUpperName,
  RightLowerName,
  RightNameHolder,
  RightScorePositioner,
  RightUpperName,
} from 'components/styles'
import Logo from 'components/logo'
import PropTypes from 'prop-types'
import Score from './score'
import { TeamFormat } from 'common/constants'
import { connect } from 'react-redux'
import { getParticipant } from 'common/util'

interface Props {
  court: Court
  match: Match
  matchIndex: number
  round: Round
  roundNbr: number
  participants: Participant[]
  teamFormat: string
}

const MatchBoard = ({
  court,
  match,
  matchIndex,
  round,
  roundNbr,
  participants,
  teamFormat,
}: Props) => {
  const leftUpperParticipant = getParticipant(
    participants,
    match.teams[0].players[0].id
  )
  const leftLowerParticipant =
    teamFormat === TeamFormat.Individual
      ? getParticipant(participants, match.teams[0].players[1].id)
      : null
  const rightUpperParticipant = getParticipant(
    participants,
    match.teams[1].players[0].id
  )
  const rightLowerParticipant =
    teamFormat === TeamFormat.Individual
      ? getParticipant(participants, match.teams[1].players[1].id)
      : null

  return (
    <CourtHolder>
      <Logo name={court.name} />
      <CourtStyle color={court.color}>
        <CourtSide>
          <LeftNameHolder>
            <LeftUpperName>
              {round.isFinal ? `${leftUpperParticipant!.placement}. ` : ''}{' '}
              {leftUpperParticipant!.name}
            </LeftUpperName>
          </LeftNameHolder>
          <LeftScorePositioner>
            <Score matchIndex={matchIndex} roundNbr={roundNbr} teamIndex={0} />
          </LeftScorePositioner>
          {leftLowerParticipant ? (
            <LeftNameHolder>
              <LeftLowerName>
                {round.isFinal ? `${leftLowerParticipant.placement}. ` : ''}{' '}
                {leftLowerParticipant.name}
              </LeftLowerName>
            </LeftNameHolder>
          ) : null}
        </CourtSide>
        <CourtSide>
          <RightNameHolder>
            <RightUpperName>
              {round.isFinal ? `${rightUpperParticipant!.placement}. ` : ''}{' '}
              {rightUpperParticipant!.name}
            </RightUpperName>
          </RightNameHolder>
          <RightScorePositioner>
            <Score matchIndex={matchIndex} roundNbr={roundNbr} teamIndex={1} />
          </RightScorePositioner>
          {rightLowerParticipant ? (
            <RightNameHolder>
              <RightLowerName>
                {round.isFinal ? `${rightLowerParticipant.placement}. ` : ''}{' '}
                {rightLowerParticipant.name}
              </RightLowerName>
            </RightNameHolder>
          ) : null}
        </CourtSide>
      </CourtStyle>
    </CourtHolder>
  )
}

const mapStateToProps = (
  state: { participants: Participant[]; teamFormat: string },
  ownProps: {
    court: Court
    match: Match
    matchIndex: number
    round: Round
    roundNbr: number
  }
) => ({
  court: ownProps.court,
  match: ownProps.match,
  matchIndex: ownProps.matchIndex,
  round: ownProps.round,
  roundNbr: ownProps.roundNbr,
  participants: state.participants,
  teamFormat: state.teamFormat,
})

MatchBoard.propTypes = {
  court: PropTypes.object.isRequired,
  matchIndex: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  round: PropTypes.object.isRequired,
  roundNbr: PropTypes.number.isRequired,
  participants: PropTypes.array.isRequired,
  teamFormat: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(MatchBoard)
