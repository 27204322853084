export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const getItem = (key: string, defaultValue: unknown) => {
  const item = localStorage.getItem(key)
  return item ? JSON.parse(item) : defaultValue
}

export const setItem = (key: string, value: unknown) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const deleteState = () => {
  try {
    localStorage.removeItem('state')
  } catch (err) {
    // Ignore
  }
}

export const saveState = (state: unknown) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (err) {
    // Ignore
  }
}
