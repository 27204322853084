import { ReactNode } from 'react'
import styles from './RoundButton.module.css'

const RoundButton = ({
  children,
  onClick,
  size,
  testId,
}: {
  children: ReactNode
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  size: number
  testId: string
}) => {
  return (
    <button
      className={styles.roundButton}
      data-test-id={testId}
      onClick={onClick}
      style={{ width: size, height: size, fontSize: size * 0.3 }}
    >
      {children}
    </button>
  )
}

export default RoundButton
