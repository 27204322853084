import { CenterStyle, LeftStyle, RightStyle } from './CommandRowStyles'
import { PhaseInfo, Round } from 'typings'
import { AppDispatch } from 'store'
import Button from '@material-ui/core/Button'
import Dialog from 'components/dialog'
import PropTypes from 'prop-types'
import { actionCreators } from 'actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import terms from 'common/language'
import { useIntl } from 'react-intl'
import { useState } from 'react'

interface Props {
  cancelRound: () => void
  currentPhase: PhaseInfo
  generateSummary: (rounds: Round[]) => void
  generateFinalRound: () => void
  generateRound: () => void
  newTournament: () => void
  transitionToPreviousOrLastRound: () => void
  transitionToSummary: () => void
  rounds: Round[]
  width: number
}

const RoundCommandRow = ({
  cancelRound,
  currentPhase,
  generateFinalRound,
  generateRound,
  generateSummary,
  newTournament,
  rounds,
  transitionToPreviousOrLastRound,
  transitionToSummary,
  width,
}: Props) => {
  const { formatMessage: fm } = useIntl()
  const [confirmationOpen, setConfirmationOpen] = useState(false)

  const roundNbr = currentPhase.index!

  const getTitle = () => {
    if (rounds[roundNbr].isFinal) {
      return width < 500 ? fm(terms.finalRound)[0] : fm(terms.finalRound)
    }
    return width < 500
      ? `#${roundNbr + 1}`
      : `${fm(terms.round)} ${roundNbr + 1}`
  }

  let buttonsDisabled = false
  const round = rounds[roundNbr]

  round.matches.forEach((match) => {
    match.teams.forEach((team) => {
      if (team.score === null) {
        buttonsDisabled = true
      }
    })
  })

  const createNewRoundButton = (
    <Button
      data-test-id="next-round"
      disabled={buttonsDisabled}
      style={{
        fontSize: '10px',
        marginLeft: '10px',
        marginRight: '10px',
        whiteSpace: 'nowrap',
      }}
      variant="contained"
      color="primary"
      size="large"
      onClick={() => {
        generateRound()
      }}
    >
      {fm(terms.newRound)}
    </Button>
  )
  const createFinalRoundButton =
    rounds.length < 2 || round.isFinal ? null : (
      <Button
        data-test-id="final-round"
        disabled={buttonsDisabled}
        style={{
          fontSize: '10px',
          marginLeft: '10px',
          marginRight: '10px',
        }}
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          generateFinalRound()
        }}
      >
        {fm(terms.finalRound)}
      </Button>
    )

  return (
    <>
      <Dialog
        confirmTestId="confirm-resting"
        content={fm(terms.cancelConfirmation)}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={() => {
          if (roundNbr === 0) {
            newTournament()
          } else {
            cancelRound()
            transitionToPreviousOrLastRound()
          }
          setConfirmationOpen(false)
        }}
        open={confirmationOpen}
      />
      <div style={LeftStyle}>
        <div style={{ fontSize: '15px' }}>{getTitle()}</div>
        <Button
          style={{ marginLeft: '10px' }}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setConfirmationOpen(true)}
        >
          {fm(terms.cancel)}
        </Button>
      </div>
      <div style={CenterStyle}>
        <Button
          data-test-id="standings"
          style={{
            fontSize: '10px',
            marginLeft: '10px',
            marginRight: '10px',
          }}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            generateSummary([])
            transitionToSummary()
          }}
        >
          {fm(terms.standings)}
        </Button>
      </div>
      <div style={RightStyle}>
        <div>{!round.isFinal && createNewRoundButton}</div>
        {createFinalRoundButton && <div>{createFinalRoundButton}</div>}
      </div>
    </>
  )
}

const mapStateToProps = (
  state: { currentPhase: PhaseInfo; rounds: Round[] },
  ownProps: { width: number }
) => ({
  currentPhase: state.currentPhase,
  rounds: state.rounds,
  width: ownProps.width,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(actionCreators, dispatch)

RoundCommandRow.propTypes = {
  generateRound: PropTypes.func.isRequired,
  newTournament: PropTypes.func.isRequired,
  rounds: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoundCommandRow)
