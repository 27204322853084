import type { Participant } from 'typings'
import { PlacementTrend } from 'common/constants'

export const getPlacementTrend = (participant: Participant) => {
  if (
    !participant.prevPlacement ||
    participant.prevPlacement === participant.placement
  ) {
    return PlacementTrend.Staying
  }
  return participant.placement < participant.prevPlacement
    ? PlacementTrend.Ascending
    : PlacementTrend.Descending
}

export const getTrendIndicator = (trend: string) => {
  switch (trend) {
    case PlacementTrend.Ascending:
      return '<div class="trendArrowAscending">⬆</div>'
    case PlacementTrend.Descending:
      return '<div class="trendArrowDescending">⬇</div>'
    case PlacementTrend.Staying:
      return '<div></div>'
  }
}
