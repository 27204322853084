import CustomDialog from 'components/dialog'
import PropTypes from 'prop-types'
import { terms } from 'common/terms'
import { useIntl } from 'react-intl'

interface Props {
  onClose: () => void
  onConfirm: () => void
}

const ResumeTournamentDialog = ({ onClose, onConfirm }: Props) => {
  const { formatMessage: fm } = useIntl()

  return (
    <CustomDialog
      backLabelCancel
      content={fm(terms.resumeTournamentQuestion)}
      onClose={onClose}
      onConfirm={onConfirm}
      open={true}
    />
  )
}

ResumeTournamentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default ResumeTournamentDialog
