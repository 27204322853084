import type { Term } from './terms'
import { terms } from './terms'

const messages: Record<string, Term> = {}

Object.keys(terms).forEach((key) => {
  const base = terms[key as keyof typeof terms]
  messages[key] = {
    id: base.id,
    description: base.description,
    defaultMessage: base.defaultMessage,
    long: {
      id: base.id + '_LONG',
      description: base.description,
      defaultMessage: base.long || base.defaultMessage,
    },
    tooltip: {
      id: base.id + '_TOOLTIP',
      description: base.description,
      defaultMessage: base.tooltip || base.defaultMessage,
    },
  }
})

export default messages
