export const getPotentialMatches = (
  selectableScores: number[],
  enteredNumberString: string
): string[] => {
  const remainingSelectableStrings = selectableScores.map((d) => String(d))

  const acceptableMatches: string[] = []

  remainingSelectableStrings.forEach((score) => {
    const charsToRemove = [...enteredNumberString]

    const scoreChars = [...score]
    let index = 0
    while (
      index < enteredNumberString.length &&
      index < score.length &&
      scoreChars[index] === charsToRemove[index]
    ) {
      index++
    }
    if (index === enteredNumberString.length && index <= score.length) {
      acceptableMatches.push(score)
    }
  })
  return acceptableMatches
}
