import * as actionCreators from 'actions'
import { ActionCreator, AnyAction, bindActionCreators } from 'redux'
import { MAX_NBR_OF_PARTICIPANTS, TeamFormat } from 'common/constants'
import { useRef, useState } from 'react'
import { AppDispatch } from 'store.js'
import Button from '@material-ui/core/Button'
import type { Participant } from 'typings/index'
import { ParticipantRow } from './ParticipantRow'
import PropTypes from 'prop-types'
import { SettingContainer } from './style.js'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
import terms from 'common/language'
import { useIntl } from 'react-intl'

interface Props {
  addParticipant: (name: string) => void
  participants: Participant[]
  removeParticipant: (id: string) => void
  teamFormat: string
}

const Participants = ({ addParticipant, participants, teamFormat }: Props) => {
  const [nameInput, setNameInput] = useState('')
  const [playerAlreadyAdded, setPlayerAlreadyAdded] = useState(false)
  const { formatMessage: fm } = useIntl()

  const addParticipantRef = useRef<HTMLButtonElement | null>(null)
  const scrollToAddButton = () =>
    addParticipantRef.current?.scrollIntoView({ behavior: 'smooth' })

  const participantLabel =
    teamFormat === TeamFormat.Individual
      ? fm(terms.playerName)
      : fm(terms.teamName)

  let participantInput = null
  if (participants.length < MAX_NBR_OF_PARTICIPANTS) {
    const addDisabled = nameInput === ''

    participantInput = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          autoFocus
          data-test-id="player-name-input"
          error={playerAlreadyAdded}
          inputProps={{ style: { fontSize: 14 } }} // font size of input text
          InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
          label={
            playerAlreadyAdded
              ? teamFormat === TeamFormat.Individual
                ? fm(terms.playerAlreadyAdded)
                : fm(terms.teamAlreadyAdded)
              : participantLabel
          }
          onChange={(e) => {
            setPlayerAlreadyAdded(false)
            if (e.target.value.length < 18) {
              setNameInput(e.target.value)
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && nameInput !== '') {
              if (!participants.map((p) => p.name).includes(nameInput)) {
                addParticipant(nameInput)
                setNameInput('')
                e.preventDefault()
                setTimeout(scrollToAddButton, 500)
              } else {
                setPlayerAlreadyAdded(true)
              }
            }
          }}
          value={nameInput}
        />
        <Button
          ref={addParticipantRef}
          disabled={addDisabled}
          style={{ fontSize: '10px', marginLeft: '10px' }}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            if (!participants.map((p) => p.name).includes(nameInput)) {
              addParticipant(nameInput)
              setNameInput('')
              scrollToAddButton()
            } else {
              setPlayerAlreadyAdded(true)
            }
          }}
        >
          {fm(terms.add)}
        </Button>
      </div>
    )
  }

  let participantList = null
  if (participants.length > 0) {
    participantList = (
      <div style={{ width: '100%' }}>
        {participants
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((participant, index) => (
            <ParticipantRow
              index={index}
              key={participant.id}
              participant={participant}
            />
          ))}
      </div>
    )
  }

  return (
    <SettingContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ fontSize: '25px' }}>
          {`${
            teamFormat === TeamFormat.Individual
              ? fm(terms.player)
              : fm(terms.team)
          } ${participants.length > 4 ? `(${participants.length})` : ''}`}
        </div>
        <div>{participantInput}</div>
      </div>
      {participantList}
    </SettingContainer>
  )
}

const mapStateToProps = (state: {
  participants: Participant[]
  teamFormat: string
}) => ({
  participants: state.participants,
  teamFormat: state.teamFormat,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    actionCreators as unknown as ActionCreator<AnyAction>,
    dispatch
  )

Participants.propTypes = {
  addParticipant: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
  removeParticipant: PropTypes.func.isRequired,
  teamFormat: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Participants)
