import * as actionCreators from 'actions'
import { ActionCreator, AnyAction, bindActionCreators } from 'redux'
import { useEffect, useRef, useState } from 'react'
import { AppDispatch } from 'store.js'
import Button from '@material-ui/core/Button'
import { Court } from 'typings/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { MAX_NBR_OF_COURTS } from 'common/constants'
import Popover from '@material-ui/core/Popover'
import PropTypes from 'prop-types'
import { SettingContainer } from './style.js'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { getCssColor } from 'common/util'
import terms from 'common/language'
import { useIntl } from 'react-intl'

interface Props {
  addCourt: (name: string) => void
  courts: Court[]
  removeCourt: (index: number) => void
  setCourtColor: ({
    courtName,
    color,
  }: {
    courtName: string
    color: string
  }) => void
}

const Courts = ({ addCourt, courts, removeCourt, setCourtColor }: Props) => {
  const { formatMessage: fm } = useIntl()
  const [courtName, setCourtName] = useState('')
  const [colorIndexOpen, setColorIndexOpen] = useState<number | null>(null)
  const [courtAlreadyAdded, setCourtAlreadyAdded] = useState(false)
  const addCourtRef = useRef<HTMLButtonElement | null>(null)
  const scrollToAddButton = () =>
    addCourtRef.current?.scrollIntoView({ behavior: 'smooth' })

  useEffect(() => {
    if (courtName === 'throwErrorToSentry') {
      throw new Error('Manually triggered error')
    }
  }, [courtName])

  let courtInput
  if (courts.length < MAX_NBR_OF_COURTS) {
    const addDisabled = courtName === '' || courtAlreadyAdded
    courtInput = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          autoFocus
          data-test-id="court-name-input"
          error={courtAlreadyAdded}
          inputProps={{ style: { fontSize: 14 } }} // font size of input text
          InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
          label={
            courtAlreadyAdded
              ? fm(terms.courtAlreadyAdded)
              : fm(terms.courtName)
          }
          onChange={(e) => {
            setCourtAlreadyAdded(false)
            setCourtName(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && courtName !== '') {
              if (!courts.map((p) => p.name).includes(courtName)) {
                addCourt(courtName)
                setCourtName('')
                e.preventDefault()
                setTimeout(scrollToAddButton, 500)
              } else {
                setCourtAlreadyAdded(true)
              }
            }
          }}
          value={courtName}
        />
        <Button
          ref={addCourtRef}
          disabled={addDisabled}
          style={{ fontSize: '10px', marginLeft: '10px' }}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            if (!courts.map((p) => p.name).includes(courtName)) {
              addCourt(courtName)
              setCourtName('')
              setTimeout(scrollToAddButton, 500)
            } else {
              setCourtAlreadyAdded(true)
            }
          }}
        >
          {fm(terms.add)}
        </Button>
      </div>
    )
  }

  const colorBoxStyle = {
    border: '1px solid black',
    borderRadius: '10%',
    width: '16px',
    height: '16px',
  }

  const colors = [{ color: 'blue' }, { color: 'orange' }, { color: 'green' }]

  let courtList = null
  if (courts.length > 0) {
    courtList = (
      <div style={{ width: '100%' }}>
        {courts.map((court, index) => (
          <div
            key={court.name + index}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div style={{ fontSize: '15px' }}>
              {index + 1}. {court.name}
            </div>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <Button
                onClick={() => {
                  setColorIndexOpen(index)
                }}
              >
                <div
                  style={{
                    ...colorBoxStyle,
                    backgroundColor: getCssColor(court.color),
                  }}
                />
              </Button>
              <Button
                style={{ fontSize: '10px' }}
                onClick={() => removeCourt(index)}
              >
                <FontAwesomeIcon icon={faTrashAlt as IconProp} size="2x" />
              </Button>
            </div>
            <Popover
              id={court.name + index}
              open={colorIndexOpen === index}
              onClose={() => setColorIndexOpen(index)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  height: '30px',
                }}
              >
                {colors.map((c) => (
                  <div
                    key={c.color}
                    onClick={() => {
                      setCourtColor({
                        courtName: court.name,
                        color: c.color,
                      })
                      setColorIndexOpen(null)
                    }}
                    style={{
                      ...colorBoxStyle,
                      backgroundColor: getCssColor(c.color),
                      margin: '5px',
                    }}
                  />
                ))}
              </div>
            </Popover>
          </div>
        ))}
      </div>
    )
  }

  return (
    <SettingContainer>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ fontSize: '25px' }}>{fm(terms.courts)}</div>
        <div>{courtInput}</div>
      </div>
      {courtList}
    </SettingContainer>
  )
}

const mapStateToProps = (state: { courts: Court[] }) => ({
  courts: state.courts,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    actionCreators as unknown as ActionCreator<AnyAction>,
    dispatch
  )

Courts.propTypes = {
  addCourt: PropTypes.func.isRequired,
  courts: PropTypes.array.isRequired,
  removeCourt: PropTypes.func.isRequired,
  setCourtColor: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Courts)
