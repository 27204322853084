import { PassingHolder, PassingPlayers, PassingSubTitle } from './style'
import { Participant } from 'typings'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getScoreForPassing } from 'common/util'
import terms from 'common/language'
import { useIntl } from 'react-intl'

const Passing = ({
  matchFormat,
  passingPlayers,
}: {
  matchFormat: string
  passingPlayers: Participant[]
}) => {
  const { formatMessage: fm } = useIntl()
  return (
    <PassingHolder>
      <PassingSubTitle>
        {`${fm(terms.playersResting)} ${getScoreForPassing(matchFormat)} ${fm(
          terms.points
        )}`}
      </PassingSubTitle>
      <PassingPlayers>
        {passingPlayers.map((p) => (
          <div key={p.id}>{p.name}</div>
        ))}
      </PassingPlayers>
    </PassingHolder>
  )
}

const mapStateToProps = (
  state: { matchFormat: string },
  ownProps: { passingPlayers: Participant[] }
) => ({
  matchFormat: state.matchFormat,
  passingPlayers: ownProps.passingPlayers,
})

Passing.propTypes = {
  matchFormat: PropTypes.string.isRequired,
  passingPlayers: PropTypes.array.isRequired,
}

export default connect(mapStateToProps)(Passing)
