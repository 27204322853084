import * as Const from 'common/constants'
import * as actionCreators from 'actions'
import { ActionCreator, AnyAction, bindActionCreators } from 'redux'
import { AppDispatch } from 'store'
import Courts from './courts'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FullPage } from 'components/styles'
import Participants from './participants'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'

import RadioGroup from '@material-ui/core/RadioGroup'
import { SettingContainer } from './style'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import terms from 'common/language'
import { useIntl } from 'react-intl'
import { withSize } from 'react-sizeme'

interface Props {
  finalFormat: string
  mexicanoCourtSelection: string
  mexicanoCourtSelectionSettingChanged: (value: string) => void
  matchFormat: string
  matchFormatSettingChanged: (value: string) => void
  finalFormatSettingChanged: (value: string) => void
  resultSorting: string
  resultSortingSettingChanged: (value: string) => string
  size: { width: number }
  teamFormat: string
  teamFormatSettingChanged: (value: string) => void
  tournamentType: string
  tournamentTypeSettingChanged: (value: string) => void
}

const Settings = ({
  finalFormat,
  mexicanoCourtSelection,
  mexicanoCourtSelectionSettingChanged,
  matchFormat,
  matchFormatSettingChanged,
  finalFormatSettingChanged,
  resultSorting,
  resultSortingSettingChanged,
  size,
  teamFormat,
  teamFormatSettingChanged,
  tournamentType,
  tournamentTypeSettingChanged,
}: Props) => {
  const { formatMessage: fm } = useIntl()

  const getRadioButton = (value: string, label: string) => (
    <div>
      <FormControlLabel
        value={value}
        control={<Radio />}
        label={<Typography style={{ fontSize: '15px' }}>{label}</Typography>}
      />
    </div>
  )

  const pointSetting = (
    <SettingContainer>
      <div style={{ fontSize: '25px' }}>
        {fm(terms.scoring)}
        <RadioGroup
          aria-label={fm(terms.scoring)}
          name="matchformat"
          value={matchFormat}
          onChange={(e) => matchFormatSettingChanged(e.target.value)}
        >
          {getRadioButton(Const.FOR_11_POINTS, fm(terms.play11Points))}
          {getRadioButton(Const.FOR_21_POINTS, fm(terms.play21Points))}
          {getRadioButton(Const.FOR_24_POINTS, fm(terms.play24Points))}
          {getRadioButton(Const.ANY_NBR_POINTS, fm(terms.playPointsOrGames))}
        </RadioGroup>
      </div>
    </SettingContainer>
  )

  const sortSetting = (
    <SettingContainer>
      <div style={{ fontSize: '25px' }}>
        {fm(terms.resultSorting)}
        <RadioGroup
          aria-label={fm(terms.resultSorting)}
          name={fm(terms.resultSorting)}
          value={resultSorting}
          onChange={(e) => resultSortingSettingChanged(e.target.value)}
        >
          {getRadioButton(Const.POINTS_FIRST, fm(terms.pointsFirst))}
          {getRadioButton(Const.WINS_FIRST, fm(terms.winsFirst))}
        </RadioGroup>
      </div>
    </SettingContainer>
  )

  const teamSetting = (
    <SettingContainer>
      <div style={{ fontSize: '25px' }}>
        {fm(terms.teamFormat)}
        <RadioGroup
          aria-label={fm(terms.teamFormat)}
          name={fm(terms.teamFormat)}
          value={teamFormat}
          onChange={(e) => teamFormatSettingChanged(e.target.value)}
        >
          {getRadioButton(Const.TeamFormat.Individual, fm(terms.individually))}
          {getRadioButton(Const.TeamFormat.Team, fm(terms.team))}
        </RadioGroup>
      </div>
    </SettingContainer>
  )

  const typeSetting = (
    <SettingContainer>
      <div style={{ fontSize: '25px' }}>
        {fm(terms.tournamentType)}
        <RadioGroup
          aria-label={fm(terms.tournamentType)}
          name="tournamentType"
          value={tournamentType}
          onChange={(e) => tournamentTypeSettingChanged(e.target.value)}
        >
          {getRadioButton(Const.AMERICANO, fm(terms.americano))}
          {getRadioButton(Const.MEXICANO, fm(terms.mexicano))}
        </RadioGroup>
      </div>
    </SettingContainer>
  )

  const mexicanoCourtSelectionSetting = (
    <SettingContainer>
      <div style={{ fontSize: '25px' }}>
        {fm(terms.courtSelection)}
        <RadioGroup
          aria-label={fm(terms.courtSelection)}
          name="mexicanoCourtSelection"
          value={mexicanoCourtSelection}
          onChange={(e) => mexicanoCourtSelectionSettingChanged(e.target.value)}
        >
          {getRadioButton(
            Const.ASCENDING_COURT_ORDER,
            fm(terms.ascendingCourtOrder)
          )}
          {getRadioButton(Const.RANDOM, fm(terms.random))}
        </RadioGroup>
      </div>
    </SettingContainer>
  )

  const finalFormatSetting =
    teamFormat === Const.TeamFormat.Individual ? (
      <SettingContainer>
        <div style={{ fontSize: '25px' }}>
          {fm(terms.finalPairing)}
          <RadioGroup
            aria-label={fm(terms.finalPairing)}
            name={fm(terms.finalPairing)}
            value={finalFormat}
            onChange={(e) => finalFormatSettingChanged(e.target.value)}
          >
            {getRadioButton(
              Const.ONE_AND_TWO_VS_THREE_AND_FOUR,
              `1 & 2 ${fm(terms.versus)} 3 & 4`
            )}
            {getRadioButton(
              Const.ONE_AND_THREE_VS_TWO_AND_FOUR,
              `1 & 3 ${fm(terms.versus)} 2 & 4`
            )}
            {getRadioButton(
              Const.ONE_AND_FOUR_VS_TWO_AND_THREE,
              `1 & 4 ${fm(terms.versus)} 2 & 3`
            )}
          </RadioGroup>
        </div>
      </SettingContainer>
    ) : null

  let content = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {typeSetting}
      {tournamentType === Const.MEXICANO && mexicanoCourtSelectionSetting}
      {pointSetting}
      {finalFormatSetting}
      {sortSetting}
      {teamSetting}
      <Courts />
      <Participants />
    </div>
  )

  if (size.width > 1000) {
    content = (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <div />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'flex-start',
          }}
        >
          <div>
            {typeSetting}
            {tournamentType === Const.MEXICANO && mexicanoCourtSelectionSetting}
            {pointSetting}
            {finalFormatSetting}
            <Courts />
          </div>
          <div>
            {sortSetting}
            {teamSetting}
            <Participants />
          </div>
        </div>
        <div />
      </div>
    )
  }

  return <FullPage data-test-id="settings">{content}</FullPage>
}

const mapStateToProps = (state: {
  finalFormat: string
  matchFormat: string
  mexicanoCourtSelection: string
  resultSorting: string
  teamFormat: string
  tournamentType: string
}) => ({
  finalFormat: state.finalFormat,
  matchFormat: state.matchFormat,
  mexicanoCourtSelection: state.mexicanoCourtSelection,
  resultSorting: state.resultSorting,
  teamFormat: state.teamFormat,
  tournamentType: state.tournamentType,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    actionCreators as unknown as ActionCreator<AnyAction>,
    dispatch
  )

Settings.propTypes = {
  finalFormat: PropTypes.string.isRequired,
  generateRound: PropTypes.func.isRequired,
  mexicanoCourtSelection: PropTypes.string.isRequired,
  mexicanoCourtSelectionSettingChanged: PropTypes.func.isRequired,
  matchFormat: PropTypes.string.isRequired,
  finalFormatSettingChanged: PropTypes.func.isRequired,
  matchFormatSettingChanged: PropTypes.func.isRequired,
  resultSorting: PropTypes.string.isRequired,
  resultSortingSettingChanged: PropTypes.func.isRequired,
  size: PropTypes.object.isRequired,
  teamFormat: PropTypes.string.isRequired,
  teamFormatSettingChanged: PropTypes.func.isRequired,
  tournamentType: PropTypes.string.isRequired,
  tournamentTypeSettingChanged: PropTypes.func.isRequired,
}

export default withSize()(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
)
