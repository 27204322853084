import swishImage from 'assets/img/swish.png'

const HeartEmoji = () => (
  <span style={{ color: 'red', padding: '0px 5px' }}>♥</span>
)

const SwishNagger = () => (
  <div
    style={{
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      minWidth: '300px',
    }}
  >
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img src={swishImage} />
      <div style={{ padding: '10px' }}>
        <div>
          <div>Spelas denna turneringen i en verksamhet</div>
          <div>eller vill du bidra till att ha kvar americano.ninja?</div>
          <div>
            <HeartEmoji />
            Swisha 20 kr <HeartEmoji />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default SwishNagger
