import { Button, styled } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import terms from 'common/language'
import { useIntl } from 'react-intl'

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    minWidth: '400px', // Custom minimum width
    minHeight: '200px', // Custom minimum height
    padding: '20px', // Custom padding
    backgroundColor: '#f5f5f5', // Optional: change background color
  },
})

const StyledDialogContent = styled(DialogContent)({
  fontSize: '1.5em', // Custom font size
  lineHeight: '1.5', // Optional: custom line height
  padding: '10px 30px', // Custom padding
})

interface Props {
  backLabelCancel?: boolean
  confirmTestId?: string
  content: string
  onClose: () => void
  onConfirm: () => void
  open: boolean
}

const CustomDialog = ({
  backLabelCancel,
  confirmTestId,
  content,
  onClose,
  onConfirm,
  open,
}: Props) => {
  const { formatMessage: fm } = useIntl()

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
    >
      <StyledDialogContent>{content}</StyledDialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" style={{ fontSize: '14px' }}>
          {backLabelCancel ? fm(terms.cancel) : fm(terms.back)}
        </Button>
        <Button
          data-test-id={confirmTestId}
          onClick={onConfirm}
          color="primary"
          autoFocus
          style={{ fontSize: '14px' }}
        >
          {fm(terms.ok)}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default CustomDialog
