import * as actions from 'actions'
import * as selectors from 'reducers/selectors'
import {
  CallEffect,
  PutEffect,
  SelectEffect,
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects'
import {
  FOR_11_POINTS,
  FOR_21_POINTS,
  FOR_24_POINTS,
  ONE_AND_FOUR_VS_TWO_AND_THREE,
  ONE_AND_THREE_VS_TWO_AND_FOUR,
  ONE_AND_TWO_VS_THREE_AND_FOUR,
  POINTS_FIRST,
  TeamFormat,
} from 'common/constants'
import { Match, Participant, Phase, Round, Team } from 'typings'
import { getScoreForPassing, shuffle } from 'common/util'
import axios from 'axios'
import { getNextRound } from 'common/round'

const pointsSort = (p1: Participant, p2: Participant) => {
  const p1Wins = p1.wins.filter((v) => v).length
  const p2Wins = p2.wins.filter((v) => v).length
  if (p1.totalScore === p2.totalScore) {
    return p2Wins - p1Wins
  }
  return (p2.totalScore || 0) - (p1.totalScore || 0)
}

const winsSort = (p1: Participant, p2: Participant) => {
  const p1Wins = p1.wins.filter((v) => v).length
  const p2Wins = p2.wins.filter((v) => v).length
  if (p1Wins === p2Wins) {
    return (p2.totalScore || 0) - (p1.totalScore || 0)
  }
  return p2Wins - p1Wins
}

export const getSortedParticipants = ({
  participants,
  sorting,
}: {
  participants: Participant[]
  sorting: string
}) => [...participants].sort(sorting === POINTS_FIRST ? pointsSort : winsSort)

const scoreIsWin = ({
  matchFormat,
  score,
}: {
  matchFormat: string
  score: number
}) => {
  switch (matchFormat) {
    case FOR_11_POINTS:
      return score > 5
    case FOR_21_POINTS:
      return score > 10
    case FOR_24_POINTS:
      return score > 12
    default:
      // free points winner can't be determined
      return false
  }
}

const getNbrMaxPoints = (matchFormat: string) =>
  matchFormat === FOR_21_POINTS ? 21 : matchFormat === FOR_24_POINTS ? 24 : 11

function* watchAssignScore(): Generator<
  ReturnType<typeof takeEvery>,
  void,
  unknown
> {
  yield takeEvery(
    actions.ASSIGN_SCORE,
    function* (
      action: actions.AssignScoreAction
    ): Generator<
      | SelectEffect
      | PutEffect<actions.SetScoreForMatchAction>
      | PutEffect<actions.SetStandingsAction>,
      void,
      unknown
    > {
      const { roundNbr, matchIndex, teamIndex, score } = action.report
      const rounds = selectors.rounds(yield select())

      const participants = selectors.participants(yield select())
      const matchFormat = selectors.matchFormat(yield select())
      const teamFormat = selectors.teamFormat(yield select())
      const updatedParticipants = [...participants]

      const getPlayer = ({
        tIndex,
        pIndex,
      }: {
        tIndex: number
        pIndex: number
      }) =>
        updatedParticipants.find(
          (p) =>
            p.id ===
            rounds[roundNbr].matches[matchIndex].teams[tIndex].players[pIndex]
              .id
        ) as Participant

      const selected1 = getPlayer({ tIndex: teamIndex, pIndex: 0 })
      selected1.scores[roundNbr] = score
      selected1.wins[roundNbr] = scoreIsWin({ matchFormat, score })
      if (teamFormat === TeamFormat.Individual) {
        const selected2 = getPlayer({ tIndex: teamIndex, pIndex: 1 })
        selected2.scores[roundNbr] = score
        selected2.wins[roundNbr] = scoreIsWin({ matchFormat, score })
      }
      yield put(actions.setScoreForMatch(action.report))

      const otherTeamIndex = teamIndex === 0 ? 1 : 0
      if (
        matchFormat === FOR_11_POINTS ||
        matchFormat === FOR_21_POINTS ||
        matchFormat === FOR_24_POINTS
      ) {
        const maxPoints = getNbrMaxPoints(matchFormat)
        const otherTeamScore = maxPoints - score
        const other1 = getPlayer({ tIndex: otherTeamIndex, pIndex: 0 })
        other1.scores[roundNbr] = otherTeamScore
        other1.wins[roundNbr] = scoreIsWin({
          matchFormat,
          score: otherTeamScore,
        })
        if (teamFormat === TeamFormat.Individual) {
          const other2 = getPlayer({ tIndex: otherTeamIndex, pIndex: 1 })
          other2.scores[roundNbr] = otherTeamScore
          other2.wins[roundNbr] = scoreIsWin({
            matchFormat,
            score: otherTeamScore,
          })
        }

        yield put(
          actions.setScoreForMatch({
            roundNbr,
            matchIndex,
            teamIndex: otherTeamIndex,
            score: otherTeamScore,
          })
        )
      } else {
        // Free points
        const otherTeamScore =
          rounds[roundNbr].matches[matchIndex].teams[otherTeamIndex].score
        if (otherTeamScore !== null) {
          // Other team has score registered
          getPlayer({ tIndex: teamIndex, pIndex: 0 }).wins[roundNbr] =
            score > otherTeamScore
          getPlayer({ tIndex: otherTeamIndex, pIndex: 0 }).wins[roundNbr] =
            otherTeamScore > score
          if (teamFormat === TeamFormat.Individual) {
            getPlayer({ tIndex: teamIndex, pIndex: 1 }).wins[roundNbr] =
              score > otherTeamScore
            getPlayer({ tIndex: otherTeamIndex, pIndex: 1 }).wins[roundNbr] =
              otherTeamScore > score
          }
        }
      }

      updatedParticipants.forEach((p: Participant) => {
        p.totalScore = p.scores.reduce((total, score) => total + score)
      })

      const sorting = selectors.resultSorting(yield select())

      const sortedParticipants = getSortedParticipants({
        participants: updatedParticipants,
        sorting,
      })

      // Update based on the sorted list which we will not use more than temporarily
      sortedParticipants.forEach((_, index) => {
        sortedParticipants[index].placement = index + 1
      })

      yield put(actions.setStandings(updatedParticipants))
    }
  )
}

const getNbrPlayingPlayers = (
  nbrCourts: number,
  nbrPlayers: number,
  teamFormat: string
) =>
  teamFormat === TeamFormat.Individual
    ? 4 * Math.min(nbrCourts, Math.floor(nbrPlayers / 4))
    : 2 * Math.min(nbrCourts, Math.floor(nbrPlayers / 2))

const getPlayingAndPassingPlayers = ({
  finalRound,
  nbrPlaying,
  players,
}: {
  finalRound: boolean
  nbrPlaying: number
  players: Participant[]
}) => {
  const passing = []
  const playing = []
  const nbrPassing = players.length - nbrPlaying

  // Shuffle first to get random candidates if they have equal lastPassRound.
  const candidates = shuffle<Participant>([...players]).sort(
    (p1, p2) => p1.lastPassRound - p2.lastPassRound
  )

  for (let i = 0; i < candidates.length; i++) {
    if (passing.length === nbrPassing) {
      // We already found our passing players
      playing.push(candidates[i])
      continue
    }
    // Top four players always play the final
    if (finalRound && candidates[i].placement < 4) {
      playing.push(candidates[i])
    } else {
      passing.push(candidates[i])
    }
  }

  return {
    passing,
    playing,
  }
}

function* watchGenerateFinalRound(): Generator<
  ReturnType<typeof takeEvery>,
  void,
  unknown
> {
  yield takeEvery(actions.GENERATE_FINAL_ROUND, function* (): Generator<
    | SelectEffect
    | PutEffect<actions.AddNewRoundAction>
    | PutEffect<actions.GiveFreePointsAction>
    | PutEffect<actions.SetPhaseAction>,
    void,
    unknown
  > {
    const courts = selectors.courts(yield select())
    const rounds = selectors.rounds(yield select())
    const participants = selectors.participants(yield select())
    const teamFormat = selectors.teamFormat(yield select())
    const newRound: Round = { matches: [], isFinal: true, passingPlayers: [] }

    const nbrPlaying = getNbrPlayingPlayers(
      courts.length,
      participants.length,
      teamFormat
    )

    const { playing, passing: passingPlayers } = getPlayingAndPassingPlayers({
      finalRound: true,
      nbrPlaying,
      players: JSON.parse(JSON.stringify(participants)),
    })

    // Sort by placement for final round
    const playingPlayers = [...playing].sort(
      (a, b) => a.placement - b.placement
    )

    const playersPerMatch = teamFormat === TeamFormat.Individual ? 4 : 2
    for (let i = 0; i < nbrPlaying / playersPerMatch; i++) {
      const match: Match = { teams: [], id: i }

      const team1: Team = { players: [], score: null }
      const team2: Team = { players: [], score: null }

      const takeFirstPlayer = (playerPool: Participant[]) => {
        const player = playerPool[0]
        playerPool.splice(0, 1)
        return player
      }

      if (teamFormat === TeamFormat.Individual) {
        const finalFormat = yield select(selectors.finalFormat)
        switch (finalFormat) {
          case ONE_AND_THREE_VS_TWO_AND_FOUR:
            team1.players.push(takeFirstPlayer(playingPlayers))
            team2.players.push(takeFirstPlayer(playingPlayers))
            team1.players.push(takeFirstPlayer(playingPlayers))
            team2.players.push(takeFirstPlayer(playingPlayers))
            break
          case ONE_AND_FOUR_VS_TWO_AND_THREE:
            team1.players.push(takeFirstPlayer(playingPlayers))
            team2.players.push(takeFirstPlayer(playingPlayers))
            team2.players.push(takeFirstPlayer(playingPlayers))
            team1.players.push(takeFirstPlayer(playingPlayers))
            break
          case ONE_AND_TWO_VS_THREE_AND_FOUR:
          default:
            team1.players.push(takeFirstPlayer(playingPlayers))
            team1.players.push(takeFirstPlayer(playingPlayers))
            team2.players.push(takeFirstPlayer(playingPlayers))
            team2.players.push(takeFirstPlayer(playingPlayers))
            break
        }
      } else {
        team1.players.push(takeFirstPlayer(playingPlayers))
        team2.players.push(takeFirstPlayer(playingPlayers))
      }

      match.teams.push(team1)
      match.teams.push(team2)
      newRound.matches.push(match)
      newRound.passingPlayers = passingPlayers
    }
    yield put(actions.addNewRound(newRound, teamFormat))
    const matchFormat = selectors.matchFormat(yield select())
    for (let i = 0; i < passingPlayers.length; i++) {
      yield put(
        actions.giveFreePoints(
          passingPlayers[i].id,
          rounds.length,
          getScoreForPassing(matchFormat)
        )
      )
    }
    const currentPhase = selectors.currentPhase(yield select())
    if (currentPhase.phase === 'settings') {
      yield put(actions.setPhase({ phase: 'round', index: 0 }))
    } else if (currentPhase.phase === 'round') {
      yield put(
        actions.setPhase({ phase: 'round', index: currentPhase.index! + 1 })
      )
    } else {
      console.error(
        'Can not transition to next round from phase: ' + currentPhase.phase
      )
    }
  })
}

interface VersionResponse {
  data: {
    version: string
  }
}

const makeVersionRequest = (): Promise<VersionResponse> =>
  new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `/version.json?t=${Date.now()}`,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error))
  })

function* watchAppUpdate(): Generator<
  ReturnType<typeof takeEvery>,
  void,
  unknown
> {
  yield takeEvery([actions.APP_INITIALIZED], function* (): Generator<
    CallEffect | PutEffect<actions.UpdateAvailableAction>,
    void,
    VersionResponse
  > {
    while (true) {
      try {
        const versionResponse = yield call(makeVersionRequest)
        console.log('Version response: ', versionResponse.data.version)
        if (
          versionResponse.data.version !== import.meta.env.VITE_GIT_SHA.trim()
        ) {
          yield put(actions.updateAvailable())
        }
      } catch (_) {
        // We ignore version check errors, if network or backend is down
        // we will deal with it when a more important request fails.
      }
      yield delay(5 * 60 * 60 * 1000)
    }
  })
}

function* watchAddNewRound(): Generator<
  ReturnType<typeof takeEvery>,
  void,
  unknown
> {
  yield takeEvery([actions.ADD_NEW_ROUND], function* (): Generator<
    SelectEffect | PutEffect<actions.SetStandingsAction>,
    void,
    unknown
  > {
    const participants = selectors.participants(yield select())
    const rounds = selectors.rounds(yield select())

    const clonedParticipants = JSON.parse(JSON.stringify(participants))

    if (rounds.length > 1) {
      yield put(
        actions.setStandings(
          clonedParticipants.map((p: Participant) => ({
            ...p,
            prevPlacement: p.placement,
          }))
        )
      )
    }
  })
}

function* watchSetLanguage(): Generator<
  ReturnType<typeof takeEvery>,
  void,
  unknown
> {
  yield takeEvery([actions.SET_LANGUAGE], function* (): Generator<
    SelectEffect,
    void,
    unknown
  > {
    const language = selectors.language(yield select())
    localStorage.setItem('language', JSON.stringify(language))
  })
}

function* watchStartTournament(): Generator<
  ReturnType<typeof takeEvery>,
  void,
  unknown
> {
  yield takeEvery([actions.START_TOURNAMENT], function* (): Generator<
    SelectEffect,
    void,
    unknown
  > {
    const courts = selectors.courts(yield select())
    localStorage.setItem('courts', JSON.stringify(courts))
    const finalFormat = selectors.finalFormat(yield select())
    localStorage.setItem('finalFormat', JSON.stringify(finalFormat))
    const matchFormat = selectors.matchFormat(yield select())
    localStorage.setItem('matchFormat', JSON.stringify(matchFormat))
    const resultSorting = selectors.resultSorting(yield select())
    localStorage.setItem('resultSorting', JSON.stringify(resultSorting))
  })
}

function* watchGenerateNewRound(): Generator<
  ReturnType<typeof takeEvery>,
  void,
  unknown
> {
  yield takeEvery(actions.GENERATE_ROUND, function* (): Generator<
    | CallEffect
    | SelectEffect
    | PutEffect<actions.AddNewRoundAction>
    | PutEffect<actions.HideSpinnerAction>
    | PutEffect<actions.ShowSpinnerAction>
    | PutEffect<actions.SetPhaseAction>,
    void,
    unknown
  > {
    yield put(actions.showSpinner())
    yield delay(1000)
    const courts = selectors.courts(yield select())
    const rounds = selectors.rounds(yield select())
    const participants = selectors.participants(yield select())
    const tournamentType = selectors.tournamentType(yield select())
    const teamFormat = selectors.teamFormat(yield select())
    const mexicanoCourtSelection = selectors.mexicanoCourtSelection(
      yield select()
    )

    const { playing: playingPlayers, passing: passingPlayers } =
      getPlayingAndPassingPlayers({
        finalRound: false,
        nbrPlaying: getNbrPlayingPlayers(
          courts.length,
          participants.length,
          teamFormat
        ),
        players: JSON.parse(JSON.stringify(participants)),
      })

    const newRound = getNextRound({
      mexicanoCourtSelection,
      playingPlayers,
      teamFormat,
      tournamentType,
    })

    newRound.passingPlayers = passingPlayers
    yield put(actions.addNewRound(newRound, teamFormat))
    const matchFormat = selectors.matchFormat(yield select())
    for (let i = 0; i < passingPlayers.length; i++) {
      yield put(
        actions.giveFreePoints(
          passingPlayers[i].id,
          rounds.length,
          getScoreForPassing(matchFormat)
        )
      )
    }
    const currentPhase = selectors.currentPhase(yield select())
    if (currentPhase.phase === 'settings') {
      yield put(actions.setPhase({ phase: 'round', index: 0 }))
    } else if (currentPhase.phase === 'round') {
      yield put(
        actions.setPhase({ phase: 'round', index: currentPhase.index! + 1 })
      )
    } else {
      console.error(
        'Can not transition to next round from phase: ' + currentPhase.phase
      )
    }
    yield put(actions.hideSpinner())
  })
}

function* watchTransitionToPrevOrLastRound(): Generator<
  ReturnType<typeof takeEvery>,
  void,
  unknown
> {
  yield takeEvery(
    actions.TRANSITION_TO_PREV_OR_LAST_ROUND,
    function* (): Generator<
      SelectEffect | PutEffect<actions.SetPhaseAction>,
      void,
      unknown
    > {
      const currentPhase = selectors.currentPhase(yield select())

      const rounds = selectors.rounds(yield select())

      if (currentPhase.phase === 'round') {
        if (Number.isFinite(currentPhase.index) && currentPhase.index! > 0) {
          yield put(
            actions.setPhase({
              phase: 'round' as Phase,
              index: currentPhase.index! - 1,
            })
          )
        } else {
          console.error('Can not transition to round before first one')
        }
      } else {
        yield put(
          actions.setPhase({ phase: 'round', index: rounds.length - 1 })
        )
      }
    }
  )
}

export default function* rootSaga(): Generator<
  ReturnType<typeof all>,
  void,
  unknown
> {
  yield all([
    watchAppUpdate(),
    watchAddNewRound(),
    watchAssignScore(),
    watchGenerateNewRound(),
    watchGenerateFinalRound(),
    watchSetLanguage(),
    watchStartTournament(),
    watchTransitionToPrevOrLastRound(),
  ])
}
