import styled from 'styled-components'

export const PassingHolder = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  text-align: center;
  border: none;
`

export const PassingPlayers = styled.div`
  font-size: 20px;
`

export const PassingSubTitle = styled.div`
  font-size: 15px;
  margin: 20px 0px 10px 0px;
`

export const Title = styled.div`
  font-size: 25px;
  width: 100%;
  color: black;
  max-width: 90%;
  text-align: center;
`

export const DimmedOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
`
