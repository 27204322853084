import DkFlag from 'assets/img/dk.svg'
import EsFlag from 'assets/img/es.svg'
import GbFlag from 'assets/img/gb.svg'
import NoFlag from 'assets/img/no.svg'
import PlFlag from 'assets/img/pl.svg'
import PtFlag from 'assets/img/pt.svg'
import SeFlag from 'assets/img/se.svg'

export const getLanguages = (language: string) => {
  return [
    {
      code: 'en',
      name: 'English',
      selected: language === 'en',
      src: GbFlag,
    },
    {
      code: 'sv',
      name: 'Svenska',
      selected: language === 'sv',
      src: SeFlag,
    },
    {
      code: 'da',
      name: 'Dansk',
      selected: language === 'da',
      src: DkFlag,
    },
    {
      code: 'no',
      name: 'Norsk',
      selected: language === 'no',
      src: NoFlag,
    },
    {
      code: 'es',
      name: 'Español',
      selected: language === 'es',
      src: EsFlag,
    },
    {
      code: 'pl',
      name: 'Polski',
      selected: language === 'pl',
      src: PlFlag,
    },
    {
      code: 'pt',
      name: 'Portugal',
      selected: language === 'pt',
      src: PtFlag,
    },
  ]
}
