import * as actionCreators from 'actions'
import * as lStorage from 'localstorage'
import { ActionCreator, AnyAction, Dispatch, bindActionCreators } from 'redux'
import type { Integration, Participant, Round } from 'typings'
import { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { FullPage } from 'components/styles'
import { GridApi } from 'ag-grid-community'
import { ModeSelector } from './ModeSelector'
import { PlacementTrend } from 'common/constants'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getColumns } from './columns'
import { getPlacementTrend } from './trend'
import { getTableWidth } from './size'
import { useIntl } from 'react-intl'
import { withSize } from 'react-sizeme'

export type Size = {
  readonly width: number
  readonly height: number
}

interface Props {
  integration?: Integration
  participants: Participant[]
  setGridApi: (_: GridApi) => void
  size: Size
}

export type ViewMode = 'detailed' | 'summary'

type Row = {
  total: number
}

export const getResultRows = (participants: Participant[]) => {
  type RowWithTrend = Row & { trend: PlacementTrend }
  const rows: RowWithTrend[] = []
  participants
    .sort((a: Participant, b: Participant) => a.placement - b.placement)
    .forEach((participant: Participant) => {
      const entry = {
        total: 0,
        player: participant.name,
        id: participant.id,
        placement: participant.placement,
        trend: getPlacementTrend(participant),
        losses: 0,
        wins: 0,
      }
      participant.scores.forEach((score, scoreIndex) => {
        const win = !!participant.wins[scoreIndex]
        // @ts-expect-error fix this it's super weird
        entry[scoreIndex.toString()] = {
          score,
          win,
        }
        entry.total += score
        if (win) {
          entry.wins += 1
        } else {
          entry.losses += 1
        }
      })

      rows.push(entry)
    })

  return rows
}

const Summary = (props: Props) => {
  const { integration, participants, setGridApi, size } = props

  const { formatMessage: fm } = useIntl()
  const [viewMode, setViewMode] = useState<ViewMode>(
    lStorage.getItem('summaryViewMode', 'detailed')
  )

  // Would be nicer with a usesLocalStorage hook that encapsulated this
  useEffect(() => {
    lStorage.setItem('summaryViewMode', viewMode)
  }, [viewMode])

  return (
    <FullPage>
      <div
        className="ag-theme-balham my-custom-grid"
        id="summary-table"
        style={{
          fontSize: '20px',
          margin: '0 auto',
          height: 160 + 50 * participants.length,
          width: getTableWidth(participants, size, viewMode),
        }}
      >
        <ModeSelector setViewMode={setViewMode} viewMode={viewMode} />
        <AgGridReact
          applyColumnDefOrder={true}
          columnDefs={getColumns(fm, participants, size, viewMode, integration)}
          domLayout="autoHeight"
          gridOptions={{ rowHeight: 50 }}
          onGridReady={(params) => {
            setGridApi(params.api)
          }}
          rowData={getResultRows(participants)}
          suppressCellSelection
        />
      </div>
    </FullPage>
  )
}

const mapStateToProps = (state: {
  integration: Integration
  participants: Participant[]
  rounds: Round[]
}) => ({
  integration: state.integration,
  participants: state.participants,
  rounds: state.rounds,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators<ActionCreator<AnyAction>, ActionCreator<AnyAction>>(
    actionCreators as unknown as ActionCreator<AnyAction>,
    dispatch
  )

Summary.propTypes = {
  integration: PropTypes.object,
  participants: PropTypes.array.isRequired,
  setGridApi: PropTypes.func.isRequired,
  size: PropTypes.object.isRequired,
}

export default withSize()(connect(mapStateToProps, mapDispatchToProps)(Summary))
