import * as actionCreators from 'actions'
import { ActionCreator, AnyAction, bindActionCreators } from 'redux'
import { ButtonHolder, Panel, PanelHeader } from './style.js'
import { PhaseInfo, Report, Round } from 'typings/index.js'
import React, { useEffect } from 'react'
import { getCourtNames, getRandomInt, getTestNames } from '../../common/util.js'
import { AppDispatch } from 'store.js'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isProd } from 'common/util'
import { v4 } from 'uuid'

interface Props {
  addCourt: (name: string) => void
  addParticipant: (name: string, id: string) => void
  assignScore: (report: Report) => void
  currentPhase: PhaseInfo
  debugPanelVisible: boolean
  generateRound: () => void
  rounds: Round[]
  setDebugPanelVisibility: (visible: boolean) => void
}

const DebugPanel = ({
  addCourt,
  addParticipant,
  assignScore,
  currentPhase,
  debugPanelVisible,
  generateRound,
  rounds,
  setDebugPanelVisibility,
}: Props) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        // arrow right
        case 'ArrowLeft':
          setDebugPanelVisibility(false)
          break
        case 'ArrowRight':
          if (!isProd) {
            setDebugPanelVisibility(true)
          }
          break
        default:
          break
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [setDebugPanelVisibility])

  let content = <div />

  switch (currentPhase.phase) {
    case 'settings':
      content = (
        <React.Fragment>
          <ButtonHolder>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                getCourtNames(3).forEach((name) => addCourt(name))
                setDebugPanelVisibility(false)
              }}
            >
              Add 3 courts
            </Button>
          </ButtonHolder>
          <ButtonHolder>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                getTestNames(12).forEach((name) => addParticipant(name, v4()))
                setDebugPanelVisibility(false)
              }}
            >
              Add 12 participants
            </Button>
          </ButtonHolder>
        </React.Fragment>
      )
      break
    case 'round':
      content = (
        <React.Fragment>
          <ButtonHolder>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                rounds[currentPhase.index!].matches.forEach((_, matchIndex) => {
                  assignScore({
                    roundNbr: currentPhase.index!,
                    matchIndex,
                    score: getRandomInt(0, 21),
                    teamIndex: 0,
                  })
                })
              }}
            >
              Random results
            </Button>
          </ButtonHolder>
          <ButtonHolder>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                rounds[currentPhase.index!].matches.forEach((_, matchIndex) => {
                  assignScore({
                    roundNbr: currentPhase.index!,
                    matchIndex,
                    score: getRandomInt(0, 21),
                    teamIndex: 0,
                  })
                })
                generateRound()
              }}
            >
              Random results then next
            </Button>
          </ButtonHolder>
        </React.Fragment>
      )
      break
    default:
      break
  }

  return (
    <Drawer anchor="left" open={debugPanelVisible}>
      <Panel>
        <PanelHeader>Debug</PanelHeader>
        {content}
      </Panel>
    </Drawer>
  )
}

DebugPanel.propTypes = {
  addCourt: PropTypes.func.isRequired,
  addParticipant: PropTypes.func.isRequired,
  assignScore: PropTypes.func.isRequired,
  currentPhase: PropTypes.object.isRequired,
  debugPanelVisible: PropTypes.bool.isRequired,
  generateRound: PropTypes.func.isRequired,
  rounds: PropTypes.array.isRequired,
  setDebugPanelVisibility: PropTypes.func.isRequired,
}

const mapStateToProps = (state: {
  currentPhase: PhaseInfo
  debugPanelVisible: boolean
  rounds: Round[]
}) => ({
  currentPhase: state.currentPhase,
  debugPanelVisible: state.debugPanelVisible,
  rounds: state.rounds,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    actionCreators as unknown as ActionCreator<AnyAction>,
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DebugPanel)
