import { Dispatch, SetStateAction } from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import type { ViewMode } from './index'
import terms from 'common/language'
import { useIntl } from 'react-intl'

interface Props {
  setViewMode: Dispatch<SetStateAction<ViewMode>>
  viewMode: string
}

export const ModeSelector = ({ setViewMode, viewMode }: Props) => {
  const { formatMessage: fm } = useIntl()
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: '10px',
      }}
    >
      <div>
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={(_, newValue) => setViewMode(newValue)}
        >
          <ToggleButton value="detailed">{fm(terms.detailed)}</ToggleButton>
          <ToggleButton value="summary">{fm(terms.summary)}</ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  )
}
