import * as Const from 'common/constants'
import type { Court, Participant, PhaseInfo } from 'typings'
import { AppDispatch } from 'store'
import { type CSSProperties } from 'react'
import CommandRowContent from './CommandRowContent'
import NewVersionDialog from 'components/newversiondialog'
import { actionCreators } from 'actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withSize } from 'react-sizeme'

const RowStyle: CSSProperties = {
  alignItems: 'center',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0px 10px',
  borderTop: '2px solid #303f9f',
  height: '60px',
  position: 'fixed',
  width: '100%',
  bottom: 0,
}

const CommandRow = ({
  currentPhase,
  size,
}: {
  currentPhase: PhaseInfo
  size: { width: number; height: number }
}) => {
  return (
    <div style={RowStyle}>
      <NewVersionDialog />
      <CommandRowContent phase={currentPhase} width={size.width} />
    </div>
  )
}

const mapStateToProps = (state: {
  courts: Court[]
  currentPhase: PhaseInfo
  participants: Participant[]
  teamFormat: Const.TeamFormat
}) => ({
  courts: state.courts,
  currentPhase: state.currentPhase,
  participants: state.participants,
  teamFormat: state.teamFormat,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(actionCreators, dispatch)

export default withSize()(
  connect(mapStateToProps, mapDispatchToProps)(CommandRow)
)
