import { ActionCreator, AnyAction, bindActionCreators } from 'redux'
import { Button, TextField } from '@material-ui/core'
import {
  faCheckCircle,
  faPenToSquare,
} from '@fortawesome/free-regular-svg-icons'
import { AppDispatch } from 'store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICellRendererParams } from 'ag-grid-community'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { Participant } from 'typings'
import PropTypes from 'prop-types'
import { actionCreators } from 'actions'
import { connect } from 'react-redux'
import { useState } from 'react'

interface PlayerNameCellRendererProps extends ICellRendererParams {
  participants: Participant[]
  renameParticipant: (name: string, id: string) => void
}

const PlayerNameCellRenderer: React.FC<PlayerNameCellRendererProps> = (
  props
) => {
  const { data, participants, renameParticipant } = props
  const [updatedName, setUpdatedName] = useState(data.player)
  const [editingName, setEditingName] = useState(false)

  const trySaveName = () => {
    if (participants.map((p) => p.name).includes(updatedName)) {
      return
    }
    renameParticipant(updatedName, data.id)
    setEditingName(false)
  }

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      {editingName ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            autoFocus
            onChange={(e) => setUpdatedName(e.target.value)}
            InputProps={{ style: { fontSize: '20px' } }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                trySaveName()
              } else if (e.key === 'Escape') {
                setEditingName(false)
              }
            }}
            value={updatedName}
          ></TextField>
          <Button
            style={{ fontSize: '8px', minWidth: 'auto', padding: 5 }}
            onClick={trySaveName}
          >
            <FontAwesomeIcon icon={faCheckCircle as IconProp} size="2x" />
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div
            style={{
              maxWidth: '80%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {data.player}
          </div>
          <Button
            style={{
              color: 'gray',
              fontSize: '8px',
              minWidth: 'auto',
              padding: 5,
            }}
            onClick={() => setEditingName(true)}
          >
            <FontAwesomeIcon icon={faPenToSquare as IconProp} size="2x" />
          </Button>
        </div>
      )}
    </div>
  )
}

PlayerNameCellRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.any,
  participants: PropTypes.array.isRequired,
  renameParticipant: PropTypes.func.isRequired,
}

const mapStateToProps = (state: { participants: Participant[] }) => ({
  participants: state.participants,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    actionCreators as unknown as ActionCreator<AnyAction>,
    dispatch
  )

const Connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerNameCellRenderer)

export { Connected as PlayerNameCellRenderer }
