import { AMERICANO, MEXICANO, RANDOM, TeamFormat } from 'common/constants'
import { Match, Participant, Round, Team } from 'typings'
import {
  evaluateRound,
  shuffle,
  updatePlayerMatchingRepetitions,
} from 'common/util'

const drawRandomPlayer = (
  players: Participant[]
): [Participant, Participant[]] => {
  const slotIndex = Math.floor(players.length * Math.random())
  const player = players[slotIndex]
  const remainingPlayers = players.filter((p) => p.id !== player.id)

  return [player, remainingPlayers]
}

export const drawLeastPlayedWithPlayer = (
  player: Participant,
  players: Participant[]
) => {
  const playedWith = players
    .filter((p) => p.id !== player.id)
    .map((p) => ({ id: p.id, count: 0 }))
  Object.keys(player.playedWithCounters).forEach((partnerId) => {
    const entry = playedWith.find((p) => p.id === partnerId)
    if (entry) {
      entry.count += player.playedWithCounters[partnerId]
    }
  })

  playedWith.sort((a, b) => a.count - b.count)

  return {
    leastPlayedWith: players.find((p) => p.id === playedWith[0].id),
    remaining: players.filter((p) => p.id !== playedWith[0].id),
  }
}

export const drawLeastPlayedAgainstPlayer = (
  teamPlayers: Participant[],
  potentialOpponents: Participant[]
) => {
  const playedAgainst = potentialOpponents
    .filter((p) => !teamPlayers.map((p) => p.id).includes(p.id))
    .map((p) => ({ id: p.id, count: 0 }))

  const collectAgainstCounters = (teamPlayer: Participant) => {
    Object.keys(teamPlayer.playedAgainstCounters).forEach((opponentId) => {
      const entry = playedAgainst.find((p) => p.id === opponentId)
      if (entry) {
        entry.count += teamPlayer.playedAgainstCounters[opponentId]
      }
    })
  }

  teamPlayers.forEach((p) => collectAgainstCounters(p))
  playedAgainst.sort((a, b) => a.count - b.count)

  return {
    leastPlayedAgainst: potentialOpponents.find(
      (p) => p.id === playedAgainst[0].id
    ),
    remaining: potentialOpponents.filter((p) => p.id !== playedAgainst[0].id),
  }
}

// Order players by score, select four (or two for teams (weird format)) per court
const getBestScoreOrderedRandomRound = (
  players: Participant[],
  nbrPlayingPlayers: number,
  teamFormat: string
) => {
  const round: Round = { matches: [], isFinal: false, passingPlayers: [] }
  const sortedPlayers = players.sort(
    (p1, p2) => (p2.totalScore || 0) - (p1.totalScore || 0)
  )
  const playersPerMatch = teamFormat === TeamFormat.Individual ? 4 : 2
  for (let i = 0; i < nbrPlayingPlayers / playersPerMatch; i++) {
    let selectablePlayers = sortedPlayers.splice(
      0,
      teamFormat === TeamFormat.Individual ? 4 : 2
    )
    const match: Match = { teams: [], id: i }
    for (let j = 0; j < 2; j++) {
      const team: { players: Participant[]; score: null } = {
        players: [],
        score: null,
      }
      let player
      ;[player, selectablePlayers] = drawRandomPlayer(selectablePlayers)
      team.players.push(player)
      if (teamFormat === TeamFormat.Individual) {
        // If playing individual, draw two players per team.
        let player
        ;[player, selectablePlayers] = drawRandomPlayer(selectablePlayers)
        team.players.push(player)
      }
      match.teams.push(team)
    }
    round.matches.push(match)
  }

  return round
}

const getBestRandomRound = (
  players: Participant[],
  nbrPlayingPlayers: number,
  teamFormat: string
) => {
  const round: Round = { matches: [], isFinal: false, passingPlayers: [] }
  const playersPerMatch = teamFormat === TeamFormat.Individual ? 4 : 2
  let remainingPlayers = players
  for (let i = 0; i < nbrPlayingPlayers / playersPerMatch; i++) {
    const match: Match = { teams: [], id: i }
    const team1: Team = { players: [], score: null }

    // Draw first player or team randomly
    const [player, remaining] = drawRandomPlayer(remainingPlayers)
    remainingPlayers = remaining
    team1.players.push(player)
    if (teamFormat === TeamFormat.Individual) {
      // If playing individual, draw a second player, choose one this player has played with
      // the least.
      const { leastPlayedWith, remaining: updatedRemaining } =
        drawLeastPlayedWithPlayer(team1.players[0], remainingPlayers)
      remainingPlayers = updatedRemaining
      team1.players.push(leastPlayedWith!)
    }
    match.teams.push(team1)

    const team2: Team = { players: [], score: null }

    // Draw a player that the first ones haven't played against
    const { leastPlayedAgainst, remaining: updatedRemaining } =
      drawLeastPlayedAgainstPlayer(team1.players, remainingPlayers)
    remainingPlayers = updatedRemaining
    team2.players.push(leastPlayedAgainst!)
    if (teamFormat === TeamFormat.Individual) {
      // If playing individual, draw a second player, choose one this player has played with
      // the least.
      const { leastPlayedWith, remaining: updatedRemaining } =
        drawLeastPlayedWithPlayer(team2.players[0], remainingPlayers)
      remainingPlayers = updatedRemaining
      team2.players.push(leastPlayedWith!)
    }
    match.teams.push(team2)
    round.matches.push(match)
  }
  // Update player objects with repetitions, it's used to determine if this is a good
  // random round or not .. :] but it won't affect the store participant object.
  updatePlayerMatchingRepetitions(round.matches, players, teamFormat)

  return round
}

export const getNextRound = ({
  mexicanoCourtSelection,
  playingPlayers,
  teamFormat,
  tournamentType,
}: {
  mexicanoCourtSelection: string
  playingPlayers: Participant[]
  teamFormat: string
  tournamentType: string
}) => {
  const nbrPlayingPlayers = playingPlayers.length

  let bestRoundScore = 10000
  let bestCandidateRound
  const iterations = 50

  for (let i = 0; i < iterations; i++) {
    const roundGenerator =
      tournamentType === AMERICANO || !playingPlayers[0].placement
        ? getBestRandomRound
        : getBestScoreOrderedRandomRound

    const round = roundGenerator(
      JSON.parse(JSON.stringify(playingPlayers)),
      nbrPlayingPlayers,
      teamFormat
    )
    const stats = evaluateRound(round)
    if (stats.score < bestRoundScore) {
      bestCandidateRound = round
      bestRoundScore = stats.score
    }
  }

  if (!bestCandidateRound) {
    throw new Error('Could not find candidate round')
  }

  if (tournamentType === MEXICANO && mexicanoCourtSelection === RANDOM) {
    bestCandidateRound.matches = shuffle(bestCandidateRound.matches)
    bestCandidateRound.matches.map((match, i) => {
      match.id = i
    })
  }

  return bestCandidateRound
}
