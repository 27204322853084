import { CSSProperties } from 'react'

export const LeftStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  height: '100%',
  marginRight: 'auto',
}

export const CenterStyle: CSSProperties = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
}

export const RightStyle: CSSProperties = {
  alignItems: 'center',
  marginBottom: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '20px',
  gap: '20px',
  marginLeft: 'auto',
}
