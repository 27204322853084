import { DimmedOverlay, Title } from './style'
import {
  FOR_11_POINTS,
  FOR_21_POINTS,
  FOR_24_POINTS,
  TeamFormat,
} from 'common/constants'
import type { Participant, Report, Round } from 'typings'
import { calculateMatchButtonSize, getParticipant } from 'common/util'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AppDispatch } from '../../store'
import PropTypes from 'prop-types'
import RoundButton from './RoundButton'
import { actionCreators } from 'actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getPotentialMatches } from 'common/keyboard-input'
import terms from 'common/language'
import { useIntl } from 'react-intl'
import useWindowSize from 'hooks/useWindowSize'

interface Props {
  assignScore: (report: Report) => { type: string; report: Report }
  matchIndex: number
  matchFormat: string
  participants: Participant[]
  roundNbr: number
  rounds: Round[]
  teamIndex: number
  teamFormat: TeamFormat
}

const Score = ({
  assignScore,
  matchIndex,
  matchFormat,
  participants,
  roundNbr,
  rounds,
  teamIndex,
  teamFormat,
}: Props) => {
  const [enteredNumberString, setEnteredNumberString] = useState('')
  const [showButtonPanel, setShowButtonPanel] = useState(false)
  const [potentialMatches, setPotentialMatches] = useState<string[]>([])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { height: windowHeight, width: windowWidth } = useWindowSize()
  const { formatMessage: fm } = useIntl()

  const handleClickOutside = useCallback(
    (event) => {
      if (
        showButtonPanel &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setShowButtonPanel(false)
      }
    },
    [showButtonPanel]
  )

  useEffect(() => {
    setEnteredNumberString('')
  }, [showButtonPanel])

  let nbrButtons: number

  switch (matchFormat) {
    case FOR_11_POINTS:
      nbrButtons = 11
      break
    case FOR_21_POINTS:
      nbrButtons = 21
      break
    case FOR_24_POINTS:
      nbrButtons = 24
      break
    default:
      nbrButtons = 50
  }

  const selectablePoints = useMemo(
    () => [...Array(nbrButtons + 1).keys()],
    [nbrButtons]
  )

  const onSelection = useCallback(
    (score: string) => {
      setTimeout(() => setShowButtonPanel(false), 250)
      assignScore({
        roundNbr,
        matchIndex,
        teamIndex,
        score: parseInt(score),
      })
    },
    [assignScore, matchIndex, roundNbr, teamIndex]
  )

  const onKeyDown = useCallback(
    (e) => {
      if (!showButtonPanel) {
        return
      }
      if (e.key === 'Escape') {
        setShowButtonPanel(false)
      }
      if (e.key === 'Enter') {
        if (selectablePoints.includes(parseInt(enteredNumberString))) {
          onSelection(enteredNumberString)
          return
        }
      }

      const prevEnteredString = enteredNumberString
      let newEnteredString = enteredNumberString
      if (Number.isInteger(Number(e.key))) {
        newEnteredString += e.key
      } else if (
        ['Backspace', 'Delete'].includes(e.key) &&
        prevEnteredString.length > 0
      ) {
        newEnteredString = prevEnteredString.substring(
          0,
          prevEnteredString.length - 1
        )
      }
      if (prevEnteredString !== newEnteredString) {
        setPotentialMatches(
          getPotentialMatches(selectablePoints, newEnteredString)
        )
        setEnteredNumberString(newEnteredString)
      }
    },
    [enteredNumberString, onSelection, selectablePoints, showButtonPanel]
  )

  useEffect(() => {
    if (enteredNumberString !== '' && potentialMatches.length === 1) {
      onSelection(enteredNumberString)
    }
  }, [enteredNumberString, onSelection, potentialMatches])

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const aspectRatio = windowWidth / windowHeight
  const buttonSize = calculateMatchButtonSize({
    nbrButtons,
    windowHeight,
    windowWidth,
  })
  const area = Math.pow(buttonSize + 50, 2) * nbrButtons
  const modalHeight = Math.min(
    Math.sqrt(area / aspectRatio) + 60,
    windowHeight - 10
  )
  const modalWidth = Math.sqrt(area * aspectRatio)

  const numberButtons = selectablePoints
    .map((scoreNumber) => String(scoreNumber))
    .map((score) => {
      let matchingPart = <></>
      let remainingPart = <>{score}</>
      if (potentialMatches.includes(score)) {
        matchingPart = (
          <div style={{ color: 'blue' }}>
            {score.substring(0, enteredNumberString.length)}
          </div>
        )
        remainingPart = (
          <>{score.substring(enteredNumberString.length, score.length)}</>
        )
      }
      return (
        <RoundButton
          testId={`point-${score}`}
          key={score}
          onClick={() => onSelection(score)}
          size={buttonSize}
        >
          {matchingPart}
          {remainingPart}
        </RoundButton>
      )
    })

  const currentTeam = rounds[roundNbr].matches[matchIndex].teams[teamIndex]

  const p1 = getParticipant(participants, currentTeam.players[0].id)
  const p2 =
    teamFormat === TeamFormat.Individual
      ? getParticipant(participants, currentTeam.players[1].id)
      : null

  const orderedTestIdNamePart = [p1!.name, p2 ? p2.name : '']
    .sort()
    .reduce((a, current) => a + '-' + current, '')

  return (
    <>
      <RoundButton
        testId={`m${matchIndex + 1}-t${teamIndex + 1}${orderedTestIdNamePart}`}
        onClick={() => setShowButtonPanel(!showButtonPanel)}
        size={80}
      >
        {rounds[roundNbr].matches[matchIndex].teams[teamIndex].score !== null
          ? rounds[roundNbr].matches[matchIndex].teams[teamIndex].score
          : '?'}
      </RoundButton>
      {showButtonPanel && (
        <DimmedOverlay>
          <div
            ref={wrapperRef}
            style={{
              backgroundColor: 'white',
              display: showButtonPanel ? 'flex' : 'none',
              flexDirection: 'column',
              opacity: 1.0,
              alignItems: 'center',
              height: modalHeight,
              padding: '10px',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
              zIndex: 5,
              width: modalWidth,
            }}
          >
            <Title>
              {fm(terms.pointsFor)}{' '}
              {teamFormat === TeamFormat.Individual
                ? ` ${p1!.name} ${fm(terms.and)}${' '} ${p2!.name}`
                : p1!.name}
            </Title>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                width: '100%',
                height: '100%',
              }}
            >
              {numberButtons}
            </div>
          </div>
        </DimmedOverlay>
      )}
    </>
  )
}

const mapStateToProps = (
  state: {
    matchFormat: string
    participants: Participant[]
    rounds: Round[]
    teamFormat: TeamFormat
  },
  ownProps: { matchIndex: number; roundNbr: number; teamIndex: number }
) => ({
  matchIndex: ownProps.matchIndex,
  matchFormat: state.matchFormat,
  participants: state.participants,
  roundNbr: ownProps.roundNbr,
  rounds: state.rounds,
  teamIndex: ownProps.teamIndex,
  teamFormat: state.teamFormat,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(actionCreators, dispatch)

Score.propTypes = {
  assignScore: PropTypes.func.isRequired,
  matchIndex: PropTypes.number.isRequired,
  matchFormat: PropTypes.string.isRequired,
  participants: PropTypes.array.isRequired,
  roundNbr: PropTypes.number.isRequired,
  rounds: PropTypes.array.isRequired,
  teamIndex: PropTypes.number.isRequired,
  teamFormat: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Score)
