import type { Participant } from 'typings'
import type { Size } from './index'

export const getPlacementColumnWidth = (size: Size) =>
  size.width > 600 ? 70 : 50
export const getNameColumnWidth = (size: Size) => (size.width > 600 ? 240 : 120)
export const getSumColumnWidth = () => 80

export const getColumnWidth = (participants: Participant[], size: Size) => {
  const maxTableWidth = size.width * 0.9
  // Limit column width to make as much data as possible available to getAgGridData
  const minColumnWidth = window.Cypress ? 1 : 70
  const maxColumnWidth = 140
  const nbrResults = participants[0].scores.length
  const calculatedColumnWidth =
    (maxTableWidth -
      getPlacementColumnWidth(size) -
      getNameColumnWidth(size) -
      getSumColumnWidth()) /
    nbrResults
  return Math.max(
    minColumnWidth,
    Math.min(calculatedColumnWidth, maxColumnWidth)
  )
}

export const getTableWidth = (
  participants: Participant[],
  size: Size,
  viewMode: string
) => {
  let width =
    2 +
    getPlacementColumnWidth(size) +
    getNameColumnWidth(size) +
    getSumColumnWidth()

  if (viewMode === 'detailed') {
    width += participants[0].scores.length * getColumnWidth(participants, size)
  } else {
    width += getColumnWidth(participants, size)
  }
  return Math.min(size.width * 0.9, width)
}
