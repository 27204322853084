import 'css/index.css'
import * as Sentry from '@sentry/browser'
import App from 'components/app'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'

import { appInitialized } from 'actions'
import configureStore from 'store'

const store = configureStore().store

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: 'https://c8d961ed5dec4169b2c24ae92c2dcd80@sentry.io/1784975',
  })
  Sentry.setTag('client-version', import.meta.env.VITE_GIT_SHA)
}

store.dispatch(appInitialized())

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
