import * as actionCreators from 'actions'
import { ActionCreator, AnyAction, bindActionCreators } from 'redux'
import { AppDispatch } from 'store'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import terms from 'common/language'
import { useIntl } from 'react-intl'

interface Props {
  dismissNewVersion: () => void
  newVersion: boolean
}

const NewVersionDialog = ({ dismissNewVersion, newVersion }: Props) => {
  const { formatMessage: fm } = useIntl()

  return (
    <Dialog open={newVersion} onClose={() => dismissNewVersion()}>
      <DialogContent>
        <DialogContentText>{fm(terms.updateAvailable)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismissNewVersion()} color="primary">
          {fm(terms.updateLater)}
        </Button>
        <Button
          onClick={() => window.location.reload()}
          color="primary"
          autoFocus
        >
          {fm(terms.updateNow)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

NewVersionDialog.propTypes = {
  dismissNewVersion: PropTypes.func.isRequired,
  newVersion: PropTypes.bool.isRequired,
}

const mapStateToProps = (state: { newVersion: boolean }) => ({
  newVersion: state.newVersion,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    actionCreators as unknown as ActionCreator<AnyAction>,
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionDialog)
