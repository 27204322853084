import { LogoImg } from './style.js'
import PropTypes from 'prop-types'
import { getCourtLogoAndColor } from 'common/util'

const Logo = ({ name }: { name: string }) => {
  const { logo } = getCourtLogoAndColor(name)
  const logoElement = logo ? (
    <LogoImg src={logo} />
  ) : (
    <div
      style={{
        lineHeight: '60px',
        verticalAlign: 'center',
        fontSize: '32px',
      }}
    >
      {name}
    </div>
  )
  return <div style={{ width: '587px', height: '60px' }}>{logoElement}</div>
}

Logo.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Logo
