import { BoardRow, FullPage } from 'components/styles'
import type { Court, Round } from '../../typings'
import MatchBoard from 'components/round/matchboard'
import Passing from 'components/round/passing'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSize } from 'react-sizeme'

interface Props {
  courts: Court[]
  roundNbr: number
  rounds: Round[]
  size: { width: number }
}

const RoundComponent = ({ courts, roundNbr, rounds, size }: Props) => {
  const matchBoards = rounds[roundNbr].matches.map((match) => (
    <MatchBoard
      court={courts[match.id]}
      key={match.id}
      match={match}
      matchIndex={match.id}
      round={rounds[roundNbr]}
      roundNbr={roundNbr}
    />
  ))

  if (rounds[roundNbr].passingPlayers.length > 0) {
    matchBoards.push(
      <Passing key={1337} passingPlayers={rounds[roundNbr].passingPlayers} />
    )
  }

  let nbrRows = matchBoards.length
  let boardsPerRow = 1
  if (size.width > 1800) {
    if (nbrRows > 9) {
      nbrRows = Math.ceil(matchBoards.length / 4)
      boardsPerRow = 4
    } else {
      nbrRows = Math.ceil(matchBoards.length / 3)
      boardsPerRow = 3
    }
  } else if (size.width >= 1320) {
    nbrRows = Math.ceil(matchBoards.length / 2)
    boardsPerRow = 2
  }

  const rows = []
  for (let i = 0; i < nbrRows; i++) {
    const rowItems = []
    for (let j = 0; j < boardsPerRow; j++) {
      rowItems.push(matchBoards.shift())
    }
    rows.push(<BoardRow key={i}>{rowItems}</BoardRow>)
  }

  return <FullPage>{rows}</FullPage>
}

const mapStateToProps = (
  state: { courts: Court[]; rounds: Round[] },
  ownProps: { roundNbr: number }
) => ({
  courts: state.courts,
  roundNbr: ownProps.roundNbr,
  rounds: state.rounds,
})

RoundComponent.propTypes = {
  courts: PropTypes.array.isRequired,
  roundNbr: PropTypes.number.isRequired,
  rounds: PropTypes.array.isRequired,
  size: PropTypes.object.isRequired,
}

export default withSize()(connect(mapStateToProps)(RoundComponent))
