import * as actions from 'actions'
import * as lStorage from 'localstorage'
import {
  AMERICANO,
  ASCENDING_COURT_ORDER,
  FOR_21_POINTS,
  ONE_AND_THREE_VS_TWO_AND_FOUR,
  POINTS_FIRST,
  TeamFormat,
} from 'common/constants'
import { AnyAction, combineReducers } from 'redux'
import { Court, Participant } from 'typings'
import {
  getCourtLogoAndColor,
  updatePlayerMatchingRepetitions,
} from 'common/util'
import { v4 } from 'uuid'

const currentPhase = (phase = { phase: 'settings' }, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_PHASE:
      return action.phase
    case actions.NEW_TOURNAMENT:
      return { phase: 'settings' }
    default:
      return phase
  }
}

const debugPanelVisible = (visibility = false, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_DEBUG_PANEL_VISIBILITY:
      return action.visibility
    default:
      return visibility
  }
}

const gridApi = (api = null, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_GRID_API:
      return action.api
    default:
      return api
  }
}

const integration = (integration = null, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_INTEGRATION:
      return action.integration
    default:
      return integration
  }
}

const language = (
  language = lStorage.getItem('language', 'sv'),
  action: AnyAction
) => {
  switch (action.type) {
    case actions.SET_LANGUAGE:
      return action.value
    default:
      return language
  }
}

const mexicanoCourtSelection = (
  selectionType = lStorage.getItem(
    'mexicanoCourtSelection',
    ASCENDING_COURT_ORDER
  ),
  action: AnyAction
) => {
  switch (action.type) {
    case actions.MEXICANO_COURT_SELECTION_SETTING_CHANGED:
      return action.value
    default:
      return selectionType
  }
}

const matchFormat = (
  format = lStorage.getItem('matchFormat', FOR_21_POINTS),
  action: AnyAction
) => {
  switch (action.type) {
    case actions.MATCH_FORMAT_SETTING_CHANGED:
      return action.value
    default:
      return format
  }
}

const resultSorting = (
  sorting = lStorage.getItem('resultSorting', POINTS_FIRST),
  action: AnyAction
) => {
  switch (action.type) {
    case actions.RESULT_SORTING_SETTING_CHANGED:
      return action.value
    default:
      return sorting
  }
}
const teamFormat = (
  format = lStorage.getItem('teamFormat', TeamFormat.Individual),
  action: AnyAction
) => {
  switch (action.type) {
    case actions.TEAM_FORMAT_SETTING_CHANGED:
      return action.value
    default:
      return format
  }
}

const finalFormat = (
  format = lStorage.getItem('finalFormat', ONE_AND_THREE_VS_TWO_AND_FOUR),
  action: AnyAction
) => {
  switch (action.type) {
    case actions.FINAL_FORMAT_SETTING_CHANGED:
      return action.value
    default:
      return format
  }
}

const tournamentType = (
  type = lStorage.getItem('tournamentType', AMERICANO),
  action: AnyAction
) => {
  switch (action.type) {
    case actions.TOURNAMENT_TYPE_SETTING_CHANGED:
      return action.value
    default:
      return type
  }
}

const courts = (cs = lStorage.getItem('courts', []), action: AnyAction) => {
  switch (action.type) {
    case actions.ADD_COURT: {
      const { color } = getCourtLogoAndColor(action.name)
      return [...cs, { name: action.name, id: v4(), color }]
    }
    case actions.REMOVE_COURT: {
      const remaining = [...cs]
      remaining.splice(action.index, 1)
      return remaining
    }
    case actions.REMOVE_COURTS: {
      return []
    }
    case actions.SET_COURT_COLOR: {
      return cs.map((c: Court) =>
        c.name === action.courtName ? { ...c, color: action.color } : c
      )
    }
    default:
      return cs
  }
}

// eslint-disable-next-line complexity
const participants = (ps = [], action: AnyAction) => {
  switch (action.type) {
    case actions.ADD_PARTICIPANT:
      return [
        ...ps,
        {
          id: action.id ? new String(action.id) : v4(),
          name: action.name,
          lastPassRound: -1,
          playedAgainstCounters: {},
          playedWithCounters: {},
          wins: [],
          scores: [],
        },
      ]
    case actions.SET_STANDINGS: {
      return action.participants
    }
    case actions.RENAME_PARTICIPANT: {
      const updated = [...ps]
      return updated.map((p: Participant) => {
        if (p.id === action.id) {
          return { ...p, name: action.name }
        } else {
          return p
        }
      })
    }
    case actions.GIVE_FREE_POINTS: {
      const updatedParticipants = JSON.parse(JSON.stringify(ps))
      const targetParticipant = updatedParticipants.find(
        (p: Participant) => p.id === action.playerId
      )
      targetParticipant.scores[action.roundNbr] = action.nbrPoints
      targetParticipant.lastPassRound = action.roundNbr
      return updatedParticipants
    }
    case actions.REMOVE_PARTICIPANT: {
      const remaining = [...ps]

      return remaining.filter((p: Participant) => p.id !== action.id)
    }
    case actions.REMOVE_PARTICIPANTS: {
      return []
    }
    case actions.ADD_NEW_ROUND: {
      const updatedParticipants = [...ps].map((p: Participant) => ({
        ...p,
        scores: [...p.scores, 0],
      }))
      return updatePlayerMatchingRepetitions(
        action.round.matches,
        updatedParticipants,
        action.teamFormat
      )
    }
    case actions.CANCEL_ROUND: {
      return [...ps].map((p: Participant) => ({
        ...p,
        scores: [...p.scores].slice(0, -1),
      }))
    }
    case actions.NEW_TOURNAMENT:
      return []
    default:
      return ps
  }
}

const rounds = (rs = [], action: AnyAction) => {
  switch (action.type) {
    case actions.ADD_NEW_ROUND: {
      return [...rs, action.round]
    }
    case actions.CANCEL_ROUND: {
      return [...rs].slice(0, -1)
    }
    case actions.SET_SCORE_FOR_MATCH: {
      const newRs = JSON.parse(JSON.stringify(rs))
      const { roundNbr, matchIndex, teamIndex, score } = action.report
      newRs[roundNbr].matches[matchIndex].teams[teamIndex].score = score
      return newRs
    }
    case actions.NEW_TOURNAMENT:
      return []
    default:
      return rs
  }
}

const spinnerVisible = (show = false, action: AnyAction) => {
  switch (action.type) {
    case actions.HIDE_SPINNER:
      return false
    case actions.SHOW_SPINNER:
      return true
    default:
      return show
  }
}

const newVersion = (available = false, action: AnyAction) => {
  switch (action.type) {
    case actions.UPDATE_AVAILABLE:
      return true
    case actions.DISMISS_NEW_VERSION:
      return false
    default:
      return available
  }
}

export default combineReducers({
  commitHash: () => import.meta.env.VITE_GIT_SHA,
  courts,
  currentPhase,
  debugPanelVisible,
  finalFormat,
  gridApi,
  integration,
  language,
  matchFormat,
  mexicanoCourtSelection,
  participants,
  newVersion,
  resultSorting,
  rounds,
  spinnerVisible,
  teamFormat,
  tournamentType,
})
