export type Term = {
  id: string
  description: string
  defaultMessage: string
  long: string | { id: string; description: string; defaultMessage: string }
  tooltip: string | { id: string; description: string; defaultMessage: string }
}

export const terms = {
  about: {
    id: 'about',
    description: '',
    defaultMessage: '#About',
    long: '#About',
    tooltip: '#About',
  },
  ascendingCourtOrder: {
    id: 'ascendingCourtOrder',
    description: '',
    defaultMessage: '#Ascending court order',
    long: '#Ascending court order',
    tooltip: '#Ascending court order',
  },
  add: {
    id: 'add',
    description: '',
    defaultMessage: '#Add',
    long: '#Add',
    tooltip: '#Add',
  },
  americano: {
    id: 'americano',
    description: '',
    defaultMessage: '#Americano',
    long: '#Americano',
    tooltip: '#Americano',
  },
  and: {
    id: 'and',
    description: '',
    defaultMessage: '#and',
    long: '#and',
    tooltip: '#and',
  },
  back: {
    id: 'back',
    description: '',
    defaultMessage: '#Back',
    long: '#Back',
    tooltip: '#Back',
  },
  cancel: {
    id: 'cancel',
    description: '',
    defaultMessage: '#Cancel',
    long: '#Cancel',
    tooltip: '#Cancel',
  },
  cancelConfirmation: {
    id: 'cancelConfirmation',
    description: '',
    defaultMessage: '#CancelConfirmation',
    long: '#CancelConfirmation',
    tooltip: '#CancelConfirmation',
  },
  contactMe: {
    id: 'contactMe',
    description: '',
    defaultMessage:
      '#If you have questions, suggestions or find bugs, please contact me:',
    long: '#If you have questions, suggestions or find bugs, please contact me:',
    tooltip:
      '#If you have questions, suggestions or find bugs, please contact me:',
  },
  courtAlreadyAdded: {
    id: 'courtAlreadyAdded',
    description: '',
    defaultMessage: '#Court already added',
    long: '#Court already added',
    tooltip: '#Court already added',
  },
  courtName: {
    id: 'courtName',
    description: '',
    defaultMessage: '#Court name',
    long: '#Court name',
    tooltip: '#Court name',
  },
  courtSelection: {
    id: 'courtSelection',
    description: '',
    defaultMessage: '#Court selection',
    long: '#Court selection',
    tooltip: '#Court selection',
  },
  courts: {
    id: 'courts',
    description: '',
    defaultMessage: '#Courts',
    long: '#Courts',
    tooltip: '#Courts',
  },
  detailed: {
    id: 'detailed',
    description: '',
    defaultMessage: '#Detailed',
    long: '#Detailed',
    tooltip: '#Detailed',
  },
  export: {
    id: 'export',
    description: '',
    defaultMessage: '#Export',
    long: '#Export',
    tooltip: '#Export',
  },
  finalPairing: {
    id: 'finalPairing',
    description: '',
    defaultMessage: '#Final pairing',
    long: '#Final pairing',
    tooltip: '#Final pairing',
  },
  finalRound: {
    id: 'finalRound',
    description: '',
    defaultMessage: '#Final round',
    long: '#Final round',
    tooltip: '#Final round',
  },
  individually: {
    id: 'individually',
    description: '',
    defaultMessage: '#Individually',
    long: '#Individually',
    tooltip: '#Individually',
  },
  mexicano: {
    id: 'mexicano',
    description: '',
    defaultMessage: '#Mexicano',
    long: '#Mexicano',
    tooltip: '#Mexicano',
  },
  nbrRestingPlayers: {
    id: 'nbrRestingPlayers',
    description: '',
    defaultMessage: '#Number of resting players each round',
    long: '#Number of resting players each round',
    tooltip: '#Number of resting players each round',
  },
  nbrRestingTeams: {
    id: 'nbrRestingTeams',
    description: '',
    defaultMessage: '#Number of resting teams each round',
    long: '#Number of resting teams each round',
    tooltip: '#Number of resting teams each round',
  },
  newRound: {
    id: 'newRound',
    description: '',
    defaultMessage: '#New round',
    long: '#New round',
    tooltip: '#New round',
  },
  newTournament: {
    id: 'newTournament',
    description: '',
    defaultMessage: '#New tournament',
    long: '#New tournament',
    tooltip: '#New tournament',
  },
  ok: {
    id: 'ok',
    description: '',
    defaultMessage: '#Ok',
    long: '#Ok',
    tooltip: '#Ok',
  },
  placementShort: {
    id: 'placementShort',
    description: '',
    defaultMessage: '#Pos',
    long: '#Pos',
    tooltip: '#Pos',
  },
  play11Points: {
    id: 'play11Points',
    description: '',
    defaultMessage: '#Play 11 points',
    long: '#Play 11 points',
    tooltip: '#Play 11 points',
  },
  play21Points: {
    id: 'play21Points',
    description: '',
    defaultMessage: '#Play 21 points',
    long: '#Play 21 points',
    tooltip: '#Play 21 points',
  },
  play24Points: {
    id: 'play24Points',
    description: '',
    defaultMessage: '#Play 24 points',
    long: '#Play 24 points',
    tooltip: '#Play 24 points',
  },
  playPointsOrGames: {
    id: 'playPointsOrGames',
    description: '',
    defaultMessage: '#Play points or games',
    long: '#Play points or games',
    tooltip: '#Play points or games',
  },
  player: {
    id: 'player',
    description: '',
    defaultMessage: '#Player',
    long: '#Player',
    tooltip: '#Player',
  },
  playerAlreadyAdded: {
    id: 'playerAlreadyAdded',
    description: '',
    defaultMessage: '#Player already added',
    long: '#Player already added',
    tooltip: '#Player already added',
  },
  playerName: {
    id: 'playerName',
    description: '',
    defaultMessage: '#Player name',
    long: '#Player name',
    tooltip: '#Player name',
  },
  playersResting: {
    id: 'playersResting',
    description: '',
    defaultMessage: '#Players resting are awarded',
    long: '#Players resting are awarded',
    tooltip: '#Players resting are awarded',
  },
  points: {
    id: 'points',
    description: '',
    defaultMessage: '#points',
    long: '#points',
    tooltip: '#points',
  },
  pointsFirst: {
    id: 'pointsFirst',
    description: '',
    defaultMessage: '#Points first',
    long: '#Points first',
    tooltip: '#Points first',
  },
  pointsFor: {
    id: 'pointsFor',
    description: '',
    defaultMessage: '#Points for',
    long: '#Points for',
    tooltip: '#Points for',
  },
  random: {
    id: 'random',
    description: '',
    defaultMessage: '#Random',
    long: '#Random',
    tooltip: '#Random',
  },
  resultSorting: {
    id: 'resultSorting',
    description: '',
    defaultMessage: '#Result sorting',
    long: '#Result sorting',
    tooltip: '#Result sorting',
  },
  resumeTournamentQuestion: {
    id: 'resumeTournamentQuestion',
    description: '',
    defaultMessage: '#Resume tournament?',
    long: '#Resume tournament?',
    tooltip: '#Resume tournament?',
  },
  round: {
    id: 'round',
    description: '',
    defaultMessage: '#Round',
    long: '#Round',
    tooltip: '#Round',
  },
  scoring: {
    id: 'scoring',
    description: '',
    defaultMessage: '#Scoring',
    long: '#Scoring',
    tooltip: '#Scoring',
  },
  standings: {
    id: 'standings',
    description: '',
    defaultMessage: '#Standings',
    long: '#Standings',
    tooltip: '#Standings',
  },
  startTournament: {
    id: 'startTournament',
    description: '',
    defaultMessage: '#Start Tournament',
    long: '#Start Tournament',
    tooltip: '#Start Tournament',
  },
  summary: {
    id: 'summary',
    description: '',
    defaultMessage: '#Summary',
    long: '#Summary',
    tooltip: '#Summary',
  },
  team: {
    id: 'team',
    description: '',
    defaultMessage: '#Team',
    long: '#Team',
    tooltip: '#Team',
  },
  teamAlreadyAdded: {
    id: 'teamAlreadyAdded',
    description: '',
    defaultMessage: '#Team already added',
    long: '#Team already added',
    tooltip: '#Team already added',
  },
  teamFormat: {
    id: 'teamFormat',
    description: '',
    defaultMessage: '#Team format',
    long: '#Team format',
    tooltip: '#Team format',
  },
  teamName: {
    id: 'teamName',
    description: '',
    defaultMessage: '#Team name',
    long: '#Team name',
    tooltip: '#Team name',
  },
  total: {
    id: 'total',
    description: '',
    defaultMessage: '#Total',
    long: '#Total',
    tooltip: '#Total',
  },
  tournamentType: {
    id: 'tournamentType',
    description: '',
    defaultMessage: '#Tournament type',
    long: '#Tournament type',
    tooltip: '#Tournament type',
  },
  updateAvailable: {
    id: 'updateAvailable',
    description: '',
    defaultMessage: '#There is an update available.',
    long: '#There is an update available.',
    tooltip: '#There is an update available.',
  },
  updateLater: {
    id: 'updateLater',
    description: '',
    defaultMessage: '#Update later',
    long: '#Update later',
    tooltip: '#Update later',
  },
  updateNow: {
    id: 'updateNow',
    description: '',
    defaultMessage: '#Update now',
    long: '#Update now',
    tooltip: '#Update now',
  },
  versus: {
    id: 'versus',
    description: '',
    defaultMessage: '#vs',
    long: '#vs',
    tooltip: '#vs',
  },
  wins: {
    id: 'wins',
    description: '',
    defaultMessage: '#Wins',
    long: '#Wins',
    tooltip: '#Wins',
  },
  winsFirst: {
    id: 'winsFirst',
    description: '',
    defaultMessage: '#Wins first',
    long: '#Wins first',
    tooltip: '#Wins first',
  },
}
