import { PhaseInfo, Round } from 'typings'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { AppDispatch } from 'store'
import CommandRow from 'components/commandrow/CommandRow'
import DebugPanel from 'components/debugpanel'
import { IntlProvider } from 'react-intl'
import PropTypes from 'prop-types'
import QueryParamExtractor from './query-param-extractor'
import ReactGA from 'react-ga'
import ResumeTournamentDialog from 'components/settings/resumeTournamentDialog'
import RoundComponent from 'components/round'
import Settings from 'components/settings'
import Spinner from 'components/spinner'
import Summary from 'components/summary'
import { actionCreators } from 'actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { loadState } from 'localstorage'
import messagesDa from 'translations/da.json'
import messagesEn from 'translations/en.json'
import messagesEs from 'translations/es.json'
import messagesNo from 'translations/no.json'
import messagesPl from 'translations/pl.json'
import messagesPt from 'translations/pt.json'
import messagesSv from 'translations/sv.json'

const messages = {
  da: messagesDa,
  en: messagesEn,
  es: messagesEs,
  no: messagesNo,
  pl: messagesPl,
  pt: messagesPt,
  sv: messagesSv,
}

interface Props {
  currentPhase: PhaseInfo
  language: string
  newTournament: () => void
  spinnerVisible: boolean
}

const App = ({
  currentPhase,
  language,
  newTournament,
  spinnerVisible,
}: Props) => {
  const [showResumeDialog, setShowResumeDialog] = useState(false)

  useEffect(() => {
    if (window.location.hostname != 'localhost') {
      ReactGA.initialize('UA-131516570-1')
      ReactGA.pageview('/')
    }
    console.log('client version: ', import.meta.env.VITE_GIT_SHA)
  }, [])

  useEffect(() => {
    const storedState = loadState()
    if (storedState?.currentPhase.phase !== 'settings') {
      setShowResumeDialog(true)
    }
  }, [])

  if (window.location.search) {
    return <QueryParamExtractor />
  }

  let phasePage = null
  switch (currentPhase.phase) {
    case 'settings':
      phasePage = <Settings />
      break
    case 'round':
      phasePage = <RoundComponent roundNbr={currentPhase.index!} />
      break
    case 'summary':
      phasePage = <Summary />
      break
    default:
      phasePage = <div>Unexpected phase: {currentPhase.phase}</div>
      break
  }
  const spinner = spinnerVisible ? <Spinner /> : null

  const theme = createTheme({
    typography: {
      fontFamily: 'Roboto',
      fontSize: 14,
    },
  })
  return (
    /* @ts-expect-error react-intl not exporting the types needed */
    <IntlProvider locale={language} messages={messages[language]}>
      <ThemeProvider theme={theme}>
        <div style={{ fontFamily: 'Roboto, sans-serif' }}>
          {spinner}
          {showResumeDialog && (
            <ResumeTournamentDialog
              onClose={() => {
                newTournament()
                setShowResumeDialog(false)
              }}
              onConfirm={() => {
                setShowResumeDialog(false)
              }}
            />
          )}
          {!showResumeDialog && phasePage}
          <DebugPanel />
          {!showResumeDialog && <CommandRow />}
        </div>
      </ThemeProvider>
    </IntlProvider>
  )
}

const mapStateToProps = (state: {
  currentPhase: PhaseInfo
  language: string
  rounds: Round[]
  spinnerVisible: boolean
}) => ({
  currentPhase: state.currentPhase,
  language: state.language,
  rounds: state.rounds,
  spinnerVisible: state.spinnerVisible,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(actionCreators, dispatch)

App.propTypes = {
  currentPhase: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  newTournament: PropTypes.func.isRequired,
  rounds: PropTypes.array.isRequired,
  spinnerVisible: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
