export const AMERICANO = 'americano'
export const MEXICANO = 'mexicano'

export enum TeamFormat {
  Individual = 'individual',
  Team = 'team',
}

export enum PlacementTrend {
  Ascending = 'ascending',
  Descending = 'descending',
  Staying = 'staying',
}

export const ASCENDING_COURT_ORDER = 'ascendingCourtOrder'
export const RANDOM = 'random'

export const POINTS_FIRST = 'pointsFirst'
export const WINS_FIRST = 'winsFirst'

export const MAX_NBR_OF_PARTICIPANTS = 48
export const MIN_NBR_OF_PARTICIPANTS = 4

export const MAX_NBR_OF_COURTS = 12
export const MIN_NBR_OF_COURTS = 1

export const FOR_11_POINTS = 'eleven'
export const FOR_21_POINTS = 'twennione'
export const FOR_24_POINTS = 'twennifour'
export const ANY_NBR_POINTS = 'anynbrpoints'

export const MATCHMAKE_EVEN = 'matchmakeEvent'
export const MATCHMAKE_RANDOM = 'matchmakeRandom'
export const MATCHMAKE_VARIED = 'matchmakeVaried'

export const ONE_AND_TWO_VS_THREE_AND_FOUR = 'oneAndTwoVsThreeAndFour'
export const ONE_AND_THREE_VS_TWO_AND_FOUR = 'oneAndThreeVsTwoAndFour'
export const ONE_AND_FOUR_VS_TWO_AND_THREE = 'oneAndFourVsTwoAndThree'
