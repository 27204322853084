import { GridLoader } from 'react-spinners'

const Spinner = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 100,
      backgroundColor: 'black',
      opacity: 0.8,
      height: '100%',
      width: '100%',
      left: 0,
      top: 0,
      overflow: 'hidden',
      position: 'fixed',
    }}
  >
    <GridLoader size={75} color={'#123abc'} loading={true} />
  </div>
)

export default Spinner
