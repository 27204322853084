import Button from '@material-ui/core/Button'
import { terms } from 'common/terms'
import { useIntl } from 'react-intl'
import { useState } from 'react'

const AboutButton = () => {
  const [open, setOpen] = useState(false)
  const { formatMessage: fm } = useIntl()

  return (
    <>
      <div
        style={{
          border: '1px solid gray',
          position: 'absolute',
          bottom: '80px',
          right: '20px',
          visibility: open ? 'visible' : 'hidden',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '12pt',
            justifyContent: 'center',
            minWidth: '350px',
            padding: '20px',
            textAlign: 'center',
          }}
        >
          {fm(terms.contactMe)}
          <a style={{ color: 'blue' }} href="mailto:ola.flisback@gmail.com">
            ola.flisback@gmail.com
          </a>
        </div>
      </div>
      <Button size="large" onClick={() => setOpen(!open)}>
        {fm(terms.about)} americano.ninja
      </Button>
    </>
  )
}

export default AboutButton
