import styled from 'styled-components'

export const ButtonHolder = styled.div`
  margin: 10px;
`

export const Panel = styled.div`
  color: black;
  margin: 20px 0 20px 0;
  text-align: center;
  display: flex;
  padding: 20px;
  align-items: 'center';
  flex-direction: column;
`

export const PanelHeader = styled.div`
  font-size: 30px;
  margin-bottom: 20px;
`
