import { CenterStyle, LeftStyle, RightStyle } from './CommandRowStyles'
import { Integration, Participant } from 'typings'
import {
  faCheckCircle,
  faCircleXmark,
} from '@fortawesome/free-regular-svg-icons'
import { AppDispatch } from 'store'
import { Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridApi } from 'ag-grid-community'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { InSweden } from 'common/util'
import PropTypes from 'prop-types'
import SwishButton from './SwishButton'
import { actionCreators } from 'actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import { getResultRows } from 'components/summary'
import { terms } from 'common/terms'
import { useIntl } from 'react-intl'
import { useState } from 'react'

const SendResult = {
  NONE: 'NONE',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
}

interface Props {
  gridApi: GridApi
  integration: Integration
  newTournament: () => void
  participants: Participant[]
  transitionToPreviousOrLastRound: () => void
  width: number
}

const getCompactLayoutWidth = (integration?: Integration) =>
  integration ? 1400 : 800

const SummaryCommandRow = ({
  gridApi,
  integration,
  newTournament,
  participants,
  transitionToPreviousOrLastRound,
  width,
}: Props) => {
  const { formatMessage: fm } = useIntl()
  const [sendResult, setSendResult] = useState(SendResult.NONE)

  return (
    <>
      <div
        style={
          width < getCompactLayoutWidth(integration) ? LeftStyle : CenterStyle
        }
      >
        <Button
          data-test-id="back"
          variant="contained"
          color="primary"
          size="large"
          style={{ fontSize: '10px' }}
          onClick={() => {
            transitionToPreviousOrLastRound()
          }}
        >
          {fm(terms.back)}
        </Button>
      </div>
      <div style={RightStyle}>
        <div
          style={{
            alignItems: 'center',
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
            gap: '20px',
            marginLeft: 'auto',
          }}
        >
          {InSweden && width > 800 && <SwishButton />}
          <Button
            style={{ fontSize: '10px' }}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              const fileName = `resultat-${format(
                new Date(),
                'yyyy-MM-dd-HH:mm'
              )}.csv`
              gridApi.exportDataAsCsv({
                allColumns: true,
                fileName,
                processCellCallback: (params) => {
                  if (!params || !params.value) {
                    return 0
                  }
                  return typeof params.value.score !== 'undefined'
                    ? params.value.score
                    : params.value
                },
              })
            }}
          >
            {fm(terms.export)}
          </Button>
          {integration?.vendor === 'kluby' && (
            <>
              {integration.resultURL && (
                <Button
                  color="primary"
                  onClick={() => {
                    fetch(integration.resultURL!, {
                      method: 'POST',
                      body: JSON.stringify(
                        getResultRows(participants).map(
                          ({ trend, ...rest }) => rest
                        )
                      ),
                    })
                      .then((res) => {
                        if (res.status === 200) {
                          setSendResult(SendResult.SUCCESS)
                        } else {
                          setSendResult(SendResult.FAILURE)
                        }
                      })
                      .catch(() => setSendResult(SendResult.FAILURE))
                  }}
                  size="large"
                  style={{ minWidth: 'auto', whiteSpace: 'nowrap' }}
                  variant="contained"
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Send to Kluby
                    {
                      <FontAwesomeIcon
                        size="2x"
                        style={{
                          visibility:
                            sendResult === SendResult.NONE
                              ? 'hidden'
                              : 'visible',
                          marginLeft: '5px',
                        }}
                        icon={
                          (sendResult === SendResult.SUCCESS
                            ? (faCheckCircle as IconProp)
                            : (faCircleXmark as IconProp)) as IconProp
                        }
                      />
                    }
                  </div>
                </Button>
              )}
              {integration.returnURL && (
                <Button
                  color="primary"
                  onClick={() =>
                    (window.location.href = integration.returnURL!)
                  }
                  size="large"
                  style={{ minWidth: 'auto', whiteSpace: 'nowrap' }}
                  variant="contained"
                >
                  Return to Kluby
                </Button>
              )}
            </>
          )}
          <Button
            data-test-id="new-tournament"
            style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              newTournament()
            }}
          >
            {fm(terms.newTournament)}
          </Button>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (
  state: {
    gridApi: GridApi
    integration: Integration
    participants: Participant[]
  },
  ownProps: { width: number }
) => ({
  gridApi: state.gridApi,
  integration: state.integration,
  participants: state.participants,
  width: ownProps.width,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(actionCreators, dispatch)

SummaryCommandRow.propTypes = {
  generateRound: PropTypes.func.isRequired,
  integration: PropTypes.object,
  newTournament: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
  transitionToPreviousOrLastRound: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryCommandRow)
