import { ActionCreator, AnyAction, bindActionCreators } from 'redux'
import { Button, Checkbox, TextField } from '@material-ui/core'
import {
  faCheckCircle,
  faPenToSquare,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons'
import { AppDispatch } from 'store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Participant } from 'typings'
import PropTypes from 'prop-types'
import { actionCreators } from 'actions'
import { connect } from 'react-redux'
import { useState } from 'react'

interface Props {
  index: number
  participant: Participant
  participants: Participant[]
  removeParticipant: (id: string) => void
  renameParticipant: (name: string, id: string) => void
}

const ParticipantRow = ({
  index,
  participant,
  participants,
  removeParticipant,
  renameParticipant,
}: Props) => {
  const [editingName, setEditingName] = useState(false)
  const [updatedName, setUpdatedName] = useState(participant.name)

  const trySaveName = () => {
    if (participants.map((p) => p.name).includes(updatedName)) {
      return
    }
    renameParticipant(updatedName, participant.id)
    setEditingName(false)
  }

  const actionIcons = (
    <div>
      {editingName ? (
        <Button
          style={{ fontSize: '8px', minWidth: 'auto', padding: 5 }}
          onClick={trySaveName}
        >
          <FontAwesomeIcon icon={faCheckCircle as IconProp} size="2x" />
        </Button>
      ) : (
        <Button
          style={{ fontSize: '8px', minWidth: 'auto', padding: 5 }}
          onClick={() => setEditingName(true)}
        >
          <FontAwesomeIcon icon={faPenToSquare as IconProp} size="2x" />
        </Button>
      )}

      <Button
        style={{ fontSize: '8px', minWidth: 'auto', padding: 5 }}
        onClick={() => removeParticipant(participant.id)}
      >
        <FontAwesomeIcon icon={faTrashAlt as IconProp} size="2x" />
      </Button>
    </div>
  )

  return (
    <div
      key={participant.name}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      {editingName ? (
        <>
          <div style={{ fontSize: '15px' }}>
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'checkbox' }}
            />
            {index + 1}.
            <TextField
              autoFocus
              onChange={(e) => setUpdatedName(e.target.value)}
              InputProps={{ style: { fontSize: '15px', marginLeft: '5px' } }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  trySaveName()
                } else if (e.key === 'Escape') {
                  setEditingName(false)
                }
              }}
              value={updatedName}
            ></TextField>
          </div>
          {actionIcons}
        </>
      ) : (
        <>
          <div style={{ fontSize: '15px' }}>
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'checkbox' }}
            />
            {index + 1}. {participant.name}
          </div>
          {actionIcons}
        </>
      )}
    </div>
  )
}

ParticipantRow.propTypes = {
  participants: PropTypes.array.isRequired,
  renameParticipant: PropTypes.func.isRequired,
}

const mapStateToProps = (state: { participants: Participant[] }) => ({
  participants: state.participants,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    actionCreators as unknown as ActionCreator<AnyAction>,
    dispatch
  )

const Connected = connect(mapStateToProps, mapDispatchToProps)(ParticipantRow)

export { Connected as ParticipantRow }
