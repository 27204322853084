import type { Integration, Participant, Phase, PhaseInfo, Round } from 'typings'
import type { ActionCreator } from 'redux'
import { GridApi } from 'ag-grid-community'
import { Report } from '../typings'
import { TeamFormat } from 'common/constants'

export const ADD_PARTICIPANT = 'addParticipant'
export const RENAME_PARTICIPANT = 'renameParticipant'
export const APP_INITIALIZED = 'appInitialized'
export const REMOVE_PARTICIPANT = 'removeParticipant'
export const REMOVE_PARTICIPANTS = 'removeParticipants'
export const ADD_COURT = 'addCourt'
export const REMOVE_COURT = 'removeCourt'
export const REMOVE_COURTS = 'removeCourts'

export const ASSIGN_SCORE = 'assignScore'
export const SET_SCORE_FOR_MATCH = 'setScoreForMatch'
export const GIVE_FREE_POINTS = 'giveFreePoints'
export const CANCEL_ROUND = 'cancelRound'
export const GENERATE_FINAL_ROUND = 'generateFinalRound'
export const GENERATE_ROUND = 'generateRound'
export const ADD_NEW_ROUND = 'addNewRound'
export const GENERATE_SUMMARY = 'generateSummary'
export const SET_LANGUAGE = 'setLanguage'
export const SET_PHASE = 'setPhase'
export const SET_STANDINGS = 'setStandings'
export const TRANSITION_TO_SUMMARY = 'transitionToSummary'
export const TRANSITION_TO_PREV_OR_LAST_ROUND = 'transitionToPrevOrLastRound'
export const SET_DEBUG_PANEL_VISIBILITY = 'setDebugPanelVisibility'
export const NEW_TOURNAMENT = 'newTournament'
export const SET_COURT_COLOR = 'setCourtColor'
export const SET_GRID_API = 'setGridApi'
export const START_TOURNAMENT = 'startTournament'
export const UPDATE_AVAILABLE = 'updateAvailable'
export const DISMISS_NEW_VERSION = 'dismissNewVersion'

export const HIDE_SPINNER = 'hideSpinner'
export const SHOW_SPINNER = 'showSpinner'

export const MEXICANO_COURT_SELECTION_SETTING_CHANGED =
  'mexicanoCourtSelectionSettingChanged'
export const MATCH_FORMAT_SETTING_CHANGED = 'matchFormatSettingChanged'
export const FINAL_FORMAT_SETTING_CHANGED = 'finalFormatSettingChanged'
export const RESULT_SORTING_SETTING_CHANGED = 'resultSortingSettingChanged'
export const TEAM_FORMAT_SETTING_CHANGED = 'teamFormatSettingChanged'
export const TOURNAMENT_TYPE_SETTING_CHANGED = 'tournamentTypeSettingChanged'

export const SET_INTEGRATION = 'setIntegration'

export const appInitialized = () => ({ type: APP_INITIALIZED })

export const generateRound = () => ({ type: GENERATE_ROUND })
type ActionCreatorGenerateRound = ActionCreator<typeof generateRound>

export const cancelRound = () => ({ type: CANCEL_ROUND })
type ActionCreatorCancelRound = ActionCreator<typeof cancelRound>
export const generateFinalRound = () => ({ type: GENERATE_FINAL_ROUND })
type ActionCreatorGenerateFinalRound = ActionCreator<typeof generateFinalRound>

export type AddNewRoundAction = {
  type: string
  round: Round
  teamFormat: TeamFormat
}

export const addNewRound = (round: Round, teamFormat: TeamFormat) => ({
  type: ADD_NEW_ROUND,
  round,
  teamFormat,
})
export type UpdateAvailableAction = {
  type: string
}
export const updateAvailable = () => ({ type: UPDATE_AVAILABLE })
export const dismissNewVersion = () => ({ type: DISMISS_NEW_VERSION })

export type HideSpinnerAction = {
  type: string
}
export type ShowSpinnerAction = {
  type: string
}
export const hideSpinner = () => ({ type: HIDE_SPINNER })
export const showSpinner = () => ({ type: SHOW_SPINNER })

export type AssignScoreAction = {
  type: string
  report: Report
}
export const assignScore = (report: Report) => ({
  type: ASSIGN_SCORE,
  report,
})
type ActionCreatorAssignScore = ActionCreator<typeof assignScore>

export type SetScoreForMatchAction = {
  type: string
  report: Report
}
export const setScoreForMatch = (report: Report) => ({
  type: SET_SCORE_FOR_MATCH,
  report,
})
export type GiveFreePointsAction = {
  type: string
  playerId: string
  roundNbr: number
  nbrPoints: number
}
export const giveFreePoints = (
  playerId: string,
  roundNbr: number,
  nbrPoints: number
) => ({
  type: GIVE_FREE_POINTS,
  playerId,
  roundNbr,
  nbrPoints,
})

export const addCourt = (name: string) => ({ type: ADD_COURT, name })
export const removeCourt = (index: number) => ({ type: REMOVE_COURT, index })
export const removeCourts = () => ({ type: REMOVE_COURTS })

export const setCourtColor = ({
  courtName,
  color,
}: {
  courtName: string
  color: string
}) => ({
  type: SET_COURT_COLOR,
  color,
  courtName,
})

export const addParticipant = (name: string, id: string) => ({
  type: ADD_PARTICIPANT,
  name,
  id,
})
type ActionCreatorAddParticipant = ActionCreator<typeof addParticipant>

export const removeParticipant = (id: string) => ({
  type: REMOVE_PARTICIPANT,
  id,
})
type ActionCreatorRemoveParticipant = ActionCreator<typeof removeParticipant>

export const renameParticipant = (name: string, id: string) => ({
  type: RENAME_PARTICIPANT,
  name,
  id,
})
type ActionCreatorRenameParticipant = ActionCreator<typeof renameParticipant>

export const removeParticipants = () => ({
  type: REMOVE_PARTICIPANTS,
})
type ActionCreatorRemoveParticipants = ActionCreator<typeof removeParticipants>

export const newTournament = () => ({ type: NEW_TOURNAMENT })
type ActionCreatorNewTournament = ActionCreator<typeof newTournament>

export type SetPhaseAction = {
  type: string
  phase: PhaseInfo
}
export const setPhase = (phase: { phase: Phase; index?: number }) => ({
  type: SET_PHASE,
  phase,
})
type ActionCreatorSetPhase = ActionCreator<typeof setPhase>

export const setLanguage = (value: string) => ({ type: SET_LANGUAGE, value })
type ActionCreatorSetLanguage = ActionCreator<typeof setLanguage>

export const setDebugPanelVisibility = (visibility: boolean) => ({
  type: SET_DEBUG_PANEL_VISIBILITY,
  visibility,
})
type ActionCreatorSetDebugPanelVisibility = ActionCreator<
  typeof setDebugPanelVisibility
>

export const transitionToSummary = () => setPhase({ phase: 'summary' })
type ActionCreatorTransitionToSummary = ActionCreator<
  typeof transitionToSummary
>
export const transitionToPreviousOrLastRound = () => ({
  type: TRANSITION_TO_PREV_OR_LAST_ROUND,
})
type ActionCreatorTransitionToPreviousOrLastRound = ActionCreator<
  typeof transitionToPreviousOrLastRound
>

export type SetStandingsAction = {
  type: string
  participants: Participant[]
}
export const setStandings = (participants: Participant[]) => ({
  type: SET_STANDINGS,
  participants,
})

export const generateSummary = (rounds: Round[]) => ({
  type: GENERATE_SUMMARY,
  rounds,
})
type ActionCreatorGenerateSummary = ActionCreator<typeof generateSummary>

export const finalFormatSettingChanged = (finalFormat: string) => ({
  type: FINAL_FORMAT_SETTING_CHANGED,
  value: finalFormat,
})
type ActionCreatorFinalFormatSettingChanged = ActionCreator<
  typeof finalFormatSettingChanged
>

export const matchFormatSettingChanged = (formatSetting: string) => ({
  type: MATCH_FORMAT_SETTING_CHANGED,
  value: formatSetting,
})
type ActionCreatorMatchFormatSettingChanged = ActionCreator<
  typeof matchFormatSettingChanged
>

export const resultSortingSettingChanged = (resultSorting: string) => ({
  type: RESULT_SORTING_SETTING_CHANGED,
  value: resultSorting,
})
type ActionCreatorResultSortingSettingChanged = ActionCreator<
  typeof resultSortingSettingChanged
>

export const teamFormatSettingChanged = (teamFormat: string) => ({
  type: TEAM_FORMAT_SETTING_CHANGED,
  value: teamFormat,
})
type ActionCreatorTeamFormatSettingChanged = ActionCreator<
  typeof teamFormatSettingChanged
>

export const mexicanoCourtSelectionSettingChanged = (
  courtSelection: string
) => ({
  type: MEXICANO_COURT_SELECTION_SETTING_CHANGED,
  value: courtSelection,
})
type ActionCreatorMexicanoCourtSelectionSettingChanged = ActionCreator<
  typeof mexicanoCourtSelectionSettingChanged
>

export const tournamentTypeSettingChanged = (tournamentType: string) => ({
  type: TOURNAMENT_TYPE_SETTING_CHANGED,
  value: tournamentType,
})

export const startTournament = () => ({ type: START_TOURNAMENT })
type ActionCreatorStartTournament = ActionCreator<typeof startTournament>

export const setIntegration = (integration: Integration) => ({
  type: SET_INTEGRATION,
  integration,
})
type ActionCreatorSetIntegration = ActionCreator<typeof setIntegration>

export const setGridApi = (api: GridApi) => ({ type: SET_GRID_API, api })
type ActionCreatorSetGridApi = ActionCreator<typeof setGridApi>

export const actionCreators = {
  addParticipant,
  assignScore,
  cancelRound,
  generateFinalRound,
  generateRound,
  removeParticipant,
  newTournament,
  renameParticipant,
  setPhase,
  setLanguage,
  setDebugPanelVisibility,
  transitionToPreviousOrLastRound,
  transitionToSummary,
  generateSummary,
  finalFormatSettingChanged,
  removeParticipants,
  resultSortingSettingChanged,
  teamFormatSettingChanged,
  mexicanoCourtSelectionSettingChanged,
  setIntegration,
  startTournament,
  setGridApi,
}

export type ActionCreators = {
  addParticipant: ActionCreatorAddParticipant
  assignScore: ActionCreatorAssignScore
  cancelRound: ActionCreatorCancelRound
  generateFinalRound: ActionCreatorGenerateFinalRound
  generateRound: ActionCreatorGenerateRound
  removeParticipant: ActionCreatorRemoveParticipant
  newTournament: ActionCreatorNewTournament
  renameParticipant: ActionCreatorRenameParticipant
  setPhase: ActionCreatorSetPhase
  setLanguage: ActionCreatorSetLanguage
  setDebugPanelVisibility: ActionCreatorSetDebugPanelVisibility
  transitionToPreviousOrLastRound: ActionCreatorTransitionToPreviousOrLastRound
  transitionToSummary: ActionCreatorTransitionToSummary
  generateSummary: ActionCreatorGenerateSummary
  finalFormatSettingChanged: ActionCreatorFinalFormatSettingChanged
  matchFormatSettingChanged: ActionCreatorMatchFormatSettingChanged
  removeParticipants: ActionCreatorRemoveParticipants
  resultSortingSettingChanged: ActionCreatorResultSortingSettingChanged
  teamFormatSettingChanged: ActionCreatorTeamFormatSettingChanged
  mexicanoCourtSelectionSettingChanged: ActionCreatorMexicanoCourtSelectionSettingChanged
  setIntegration: ActionCreatorSetIntegration
  startTournament: ActionCreatorStartTournament
  setGridApi: ActionCreatorSetGridApi
}
